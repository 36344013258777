import React, { useState, useEffect, Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";

import TableRow from "@material-ui/core/TableRow";
import {
  Grid,
  TextField,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

import { getTotalConsumptionAction } from "../../actions/ConsumptionAction";

import Row from "./Row";

const PayTable = ({ value, setTotalReceivedPay, setTotalReturneday }) => {

  const [recieve, setRecieve] = useState("");
  const [returned, setReturned] = useState("");

  const [total, setTotal] = useState({
    totalPay: 0,
  });

  useEffect(() => {
    const list = async () => {
      const data = [];

      for (let i = 0; i < value.length; i++) {
        data.push(value[i].consumptionId);
      }

      const response = await getTotalConsumptionAction(data.toString());
      setTotal(response.data);
    };
    list();
  }, []);

  const calculateTotalValue = (value, total) => {
    let data = 0;
    if (value != "") {
      data = parseInt(value) - total;
    }
    return data;
  };

  const addValueTemp = (e) => {
    setRecieve( e.target.value);
    setReturned(calculateTotalValue(e.target.value, total.totalPay))


    setTotalReceivedPay(e.target.value);
    setTotalReturneday(calculateTotalValue(e.target.value, total.totalPay));
  };


  return (
    <Fragment>
      <TableContainer>
        <Table size="small" aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Item</TableCell>
              <TableCell align="right">Mes</TableCell>
              <TableCell align="right">Monto</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map((row, index) => (
              <Row value={row} key={index}></Row>
            ))}
            <TableRow>
              <TableCell align="right"></TableCell>
              <TableCell align="right">Suma Total</TableCell>
              <TableCell align="right">{total.totalPay}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <form align="center">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              name="totalReceivedPay"
              label="Dinero recibido"
              id="outlined-start-adornment"

              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Bs</InputAdornment>
                ),
              }}
              variant="outlined"
              value={recieve}
              onChange={addValueTemp}
              size="small" 
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextField
              name="totalReturneday"
              disabled
              id="outlined-disabled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Bs</InputAdornment>
                ),
              }}
              label="Cambio"
              defaultValue="0"
              variant="outlined"
              value={returned}
              size="small" 
            />
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default PayTable;
