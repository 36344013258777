import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useStateValue } from "../../contexto/store";
import { Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { getListFineAdd, deleteFineAction,} from "../../actions/FineAction";

const ListTableConsumption = ({ valueM }) => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [data, setData] = useState([]);


  useEffect(() => {
    const list = async () => {
      const response = await getListFineAdd(valueM);
      setData(response.data);
    };
    list();
  }, [valueM]);

  const columns = [
    { title: "ConsumptionId", field: "consumptionId", hidden: true },
    { title: "ConsumptionFineId", field: "consumptionFineId", hidden: true },
    {
      title: "Mes",
      field: "dateConsumption",
      width: 500,
      editable: "onAdd",
      render: (rowData) => (
        <Typography>{dateBuild(rowData.dateConsumption)}</Typography>
      ),
    }, 
    { title: "Nombre", field: "name" },
    {
      title: "Precio",
      field: "price",
    },
    { title: "Propio", field: "owner", type: "boolean"},
  ];

  const dateBuild = (date) => {
    const newDate = new Date(date);
    const month = newDate.toLocaleDateString("default", { month: "long" });
    return month + " " + newDate.getFullYear();
  };
  
  const handleRowDelete = (oldData, resolve) => {
    deleteFineAction(oldData.consumptionFineId).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se elimino exitosamente la multa";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const list = async () => {
        const response = await getListFineAdd(valueM);
        setData(response.data);
      };
      list();
      resolve();
    });
  };  
  

  return (
    <div>
      <MaterialTable
        localization={{
          body: {
            editRow: { deleteText: "Esta seguro de eliminar la MULTA?", cancelTooltip:"Cancelar", saveTooltip:"Guardar" }, 
            emptyDataSourceMessage : "sin multas pendientes",
            deleteTooltip:"Eliminar",
         },
         
         header:{ actions : "Aciones"},
        }}
        options={{
          exportButton: false,
          actionsColumnIndex: -1,
          padding: "dense",
          search:false,
          paging: false,
          headerStyle: {
            backgroundColor: "#e8f5e9",
            color: "#000",
          },
        }}
        title="MULTAS"
        columns={columns}
        data={data}
        editable={(sesionUsuario.usuario.rolName != "Secretary") && {
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve);
            }),
        }}
        style={{ marginLeft: 2, marginRight: 2, marginBottom: 5, borderRadius: 20 }}
      />     
    </div>
  );
};

export default ListTableConsumption;
