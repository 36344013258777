import HttpCliente from "../servicios/HttpCliente";

export const saveFine = (fine) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/fines", fine)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getListFine = () => {
  return new Promise((resolve, reject) => {
    HttpCliente.getOnly("/fines").then((response) => {
      resolve(response);
    });
  });
};

export const deleteFine = (id) => {
  return new Promise((resolve, reject) => {
    HttpCliente.delete("/fines/delete/" + id).then((response) => {
      resolve(response);
    });
  });
};

export const updateFine = (id, fine) => {
  return new Promise((resolve, reject) => {
    HttpCliente.put("/fines/" + id, fine).then(
      (response) => {
        resolve(response);
      }
    );
  });
};

export const getListFineAdd = (measurerId) => {
  return new Promise((resolve) => {
    HttpCliente.get("/fines/listAdd", measurerId).then((response) => {
      resolve(response);
    });
  });
};

export const deleteFineAction = (consumptionFineId) => {
  return new Promise((resolve) => {
    HttpCliente.delete('/consumptions/deleteFine/' + consumptionFineId).then((response) => {
      resolve(response);
    });
  });
};

export const saveFineAdd = (contribution) => {
  return new Promise((resolve) => {
    HttpCliente.post("/fines/add", contribution)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};
