import React from 'react';
// import {AppBar, toolbar, Typography, makeStyles} from "@material-ui/core";
import BarSesion from './bar/BarSesion';
import { useStateValue } from '../../contexto/store';

// import { makeStyles } from '@material-ui/styles';



const AppNavbar = () => {
  
  const [{sesionUsuario} , dispatch] = useStateValue();
    

    return sesionUsuario 
      ? (sesionUsuario.autenticado == true ?  <BarSesion /> : null )
      : null;
        
    /*{ return (
      <AppBar position="static">
          <BarSesion />
      </AppBar>
       }
     
    )*/
 
  
};

export default AppNavbar;