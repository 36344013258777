import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { getListFine } from "../../actions/FineAction";
import { useStateValue } from "../../contexto/store";
import { saveFine } from "../../actions/FineAction";
import { deleteFine } from "../../actions/FineAction";
import { updateFine } from "../../actions/FineAction";

const ListFine = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();

  const columns = [
    { title: "FineId", field: "fineId", hidden: true },
    { title: "Nombre Multa", field: "name" },
    { title: "Descripcion", field: "description" },
    { title: "Monto", field: "price" , type : 'currency'},
    { title: "Automatico?", field: "automatic" , type : 'boolean'  },
    {title: "Es Propio Institucion?", field:"isOwner", type: 'boolean'},
    { title: "Dias de aplicacion", field: "dateFine"   }
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    const list = async () => {
      const response = await getListFine();
      setData(response.data);
    };
    list();
  }, []);

  const handleRowAdd = (fine, resolve) => {
    const objectFine = {
      name: fine.name,
      description: fine.description,
      price : parseFloat(fine.price),
      automatic : fine.automatic,
      dateFine : parseInt( fine.dateFine || 0),
      isOwner : fine.isOwner
    };

    saveFine(objectFine).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo exitosamente la multa";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const list = async () => {
        const response = await getListFine();
        setData(response.data);
      };
      list();
      resolve();
    });
  };

  const handleRowDelete = (oldData, resolve) => {
    deleteFine(oldData.fineId).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se elimino exitosamente la multa";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const list = async () => {
        const response = await getListFine();
        setData(response.data);
      };
      list();
      resolve();
    });
  };

  const handleRowUpdate = (newData, oldData, resolve) => {
    /*if(newData.email === "" || validateEmail(newData.email) === false){
      errorList.push("Please enter a valid email")
    }*/

    const fineObject = {
      name: newData.name,
      description: newData.description,
      price : parseFloat(newData.price),
      automatic : newData.automatic,
      dateFine : parseInt(newData.dateFine || 0),
      isOwner : newData.isOwner
    };

    updateFine(
      oldData.fineId,
      fineObject
    ).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo cambios exitosamente";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const list = async () => {
        const response = await getListFine();
        setData(response.data);
      };
      list();
      resolve();
    });
  };

  return (
    <div>
      <MaterialTable
        localization={{
          body: {
            editRow: {
              deleteText: "Esta seguro de eliminar la multa?",
            },
          },
        }}
        options={{
          exportButton: true,
          actionsColumnIndex: -1,
          paging: true,
          search: true,
          headerStyle: {
            backgroundColor: "#e8f5e9",
            color: "#000",
          },
          searchFieldStyle: {
            backgroundColor: '#fff',
            color: '#000',
            borderRadius:9,
            headerStyle: {
            backgroundColor: "#e8f5e9",
            color: "#000",
          },
          },
        }}
        title="EDICION DE MULTAS"
        columns={columns}
        data={data}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              handleRowAdd(newData, resolve);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              handleRowUpdate(newData, oldData, resolve);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve);
            }),
        }}
        style={{
          borderRadius:20, 
          boxShadow: '-5px 5px 6px 3px rgba(0, 0, 0, .75)',
          backgroundImage: 'linear-gradient(45deg, #1769aa, #1769aa, #1769aa',
        }}
      />
    </div>
  );
};

export default ListFine;
