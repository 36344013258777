import HttpCliente from "../servicios/HttpCliente";

export const saveExpenseAction = (expense) => {
  return new Promise((resolve, eject) => {
    HttpCliente.post("/expenses", expense)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getExpensesList = (institucionId) => {
  return new Promise((resolve, reject) => {
    HttpCliente.get("/expenses/list", institucionId).then((response) => {
      resolve(response);
    });
  });
};

export const getExpenseInvoce = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJasonPdf("/expenses/invoce", parameters).then((response) => {
      resolve(response);
    });
  });
};
