import HttpCliente from '../servicios/HttpCliente';

export const saveRolesAction = (roles) => {
    return new Promise((resolve, eject) => {
        HttpCliente.post('/roless', roles)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
          })
    })
}