import React from 'react';
import AssessmentIcon from "@material-ui/icons/Assessment";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { Tooltip } from '@material-ui/core';

export const MenuCaja = [
     
    {
        title:"Registrar Ingreso",
        icon:
        <Tooltip title="Registrar Ingreso">
        <AttachMoneyIcon/>
        </Tooltip>,
        link: "/entry/new"
    },
    {
        title:"Registrar Egreso",
        icon:
        <Tooltip title="Registrar Egreso">
        <MoneyOffIcon/>
        </Tooltip>,
        link: "/expense/new"
    },
    {
        title:"Flujo",
        icon:
        <Tooltip title="Ver flujo">
        <AssessmentIcon/>
        </Tooltip>,
        link: "/reports"
    },
    
];