import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import { measurerCurrent } from "../../actions/ReportAction";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const MeasurerCurrent = () => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const getInfoData = () => {
    const listPdf = async () => {
      const objectRequest = {
        toDate: toDate,
        fromDate: fromDate,
      };
      const res = await measurerCurrent(objectRequest);
      const file = new Blob([res.data], {
        type: "application/pdf",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };

    listPdf();
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" align="center">
          Medidores agregados por fecha
        </Typography>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            value={fromDate}
            onChange={setFromDate}
            margin="normal"
            id="fecha-publicacion-id"
            label="Fecha de inicio"
            format="dd/MM/yyyy"
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            cancelLabel="Cancelar"
            okLabel="Seleccionar"
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            value={toDate}
            onChange={setToDate}
            margin="normal"
            id="fecha-publicacion-idto"
            label="Fecha de fin"
            format="dd/MM/yyyy"
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            cancelLabel="Cancelar"
            okLabel="Seleccionar"
          />
        </MuiPickersUtilsProvider>
      </CardContent>

      <CardActions>
        <Button
          onClick={getInfoData}
          color="primary"
          fullWidth
          variant="contained"
        >
          Generar
        </Button>
      </CardActions>
    </Card>
  );
};

export default MeasurerCurrent;
