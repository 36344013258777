import HttpCliente from "../servicios/HttpCliente";

export const getTransferred = (code) => {
  return new Promise((resolve) => {
    HttpCliente.get("/transferred/code", code).then((response) => {
      resolve(response);
    });
  });
};



export const saveTransAssociateAction = (associate) => {
  return new Promise((resolve, ) => {
    HttpCliente.post("/transferred", associate)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};


export const getAssociateAction = (ci) => {
  return new Promise((resolve) => {
    HttpCliente.get("/transferred/ci", ci).then((response) => {
      resolve(response);
    });
  });
};
