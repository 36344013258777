import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useStateValue } from "../../contexto/store";
import { deleteContributionAction } from "../../actions/ConsumptionAction";
import { Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { getListContributionAdd } from "../../actions/ContributionAction";

const ListTableConsumption = ({ valueM }) => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [data, setData] = useState([]);

  useEffect(() => {
    const list = async () => {
      const response = await getListContributionAdd(valueM);
      setData(response.data);
    };
    list();
  }, [valueM]);

  const columns = [
    { title: "ConsumptionId", field: "consumptionId", hidden: true },
    {
      title: "ConsumptionContributionId",
      field: "consumptionContributionId",
      hidden: true,
    },
    {
      title: "Mes",
      field: "dateConsumption",
      width: 500,
      editable: "onAdd",
      render: (rowData) => (
        <Typography>{dateBuild(rowData.dateConsumption)}</Typography>
      ),
      cellStyle: {
        width: 100,
        maxWidth: 100,
        // padding:0,
        margin:0,
      },
      headerStyle: {
        width:100,
        maxWidth: 100,
        // padding:0,
        margin:0,
      }
    },
    { title: "Nombre", field: "name",
      cellStyle: {
        width: 180,
        minWidth: 180,
        // padding:0,
        // margin:0,
      },
      headerStyle: {
        
        minWidth: 180,
        // padding:0,
        // margin:0,
      } 
    },
    {
      title: "Precio",
      field: "price",
      
      cellStyle: {
        width: 60,
        maxWidth: 60,
        // padding:0,
        // margin:0,
      },
      headerStyle: {
        width:60,
        maxWidth: 60,
        // padding:0,
        // margin:0,
      } 
    },
    { title: "Propio", field: "owner", type: "boolean", cellStyle: {
      width: 160,
      maxWidth: 160,
      // padding:0,
      // margin:0,
    },
    headerStyle: {
      width:160,
      maxWidth: 160,
      // padding:0,
      // margin:0,
    }  },
  ];

  const dateBuild = (date) => {
    const newDate = new Date(date);
    const month = newDate.toLocaleDateString("default", { month: "long" });
    return month + " " + newDate.getFullYear();
  };

  const handleRowDelete = (oldData, resolve) => {
    deleteContributionAction(oldData.consumptionContributionId).then(
      (response) => {
        let message = "";

        if (response.status === 200) {
          message += "Se elimino exitosamente el aporte";
        } else {
          message += "Errores :" + Object.keys(response.data.errors);
        }

        dispatch({
          type: "OPEN_SNACKBAR",
          openMensaje: {
            open: true,
            mensaje: message,
          },
        });

        const list = async () => {
          const response = await getListContributionAdd(valueM);
          setData(response.data);
        };
        list();
        resolve();
      }
    );
  };

  return (
    <div>
      <MaterialTable
        localization={{
          body: {
            editRow: { deleteText: "Esta seguro de eliminar el APORTE?", cancelTooltip:"Cancelar", saveTooltip:"Guardar" }, 
            emptyDataSourceMessage : "sin aportes pentiendes..",
            deleteTooltip:"Eliminar",
         },
         
         header:{ actions : "Aciones"},
        }}
        options={{
          exportButton: false,
          actionsColumnIndex: -1,
          padding: "dense",
          search:false,
          paging: false,
          headerStyle: {
            backgroundColor: "#e8f5e9",
            color: "#000",
          },
        }}
        title="APORTES"
        columns={columns}
        data={data}
        editable={(sesionUsuario.usuario.rolName != "Secretary") && {
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve);
            }),
        }}
        style={{ marginLeft: 2, marginRight: 2, marginBottom: 5, borderRadius: 20 }}
      />
    </div>
  );
};

export default ListTableConsumption;
