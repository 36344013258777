import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import style from "../Tool/Style";
import { saveRolesAction } from "../../actions/RolesAction";
import { useStateValue } from "../../contexto/store";


const NewRoles = () => {
  //traer variable global de los mensajes y usuario aunque no lo use por el momento
  const [{ sesionUsuario }, dispatch] = useStateValue();

  //costante creacion de variables de objeto socio para estado
  const [roles, setRoles] = useState({
    name: "",
    normalizedName: "",
    concurrencyStamp: null,
    institutionId: "EEB5FA29-D7C9-404C-0147-08D8185B4E74",
  });

  const saveRoles = (e) => {
    e.preventDefault();

    //constante para del objeto socio para guardar en la BD seteados los valores del formulario
    const objectRoles = {
      name: roles.name,
      normalizedName: roles.normalizedName,
      concurrencyStamp: parseInt(roles.concurrencyStamp || null),
      institutionId: "EEB5FA29-D7C9-404C-0147-08D8185B4E74",
    };

    //llamar al Action socio
    saveRolesAction(objectRoles).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo exitosamente el nuevo socio";
        resetForm();
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });
    });

    const resetForm = () => {
      setRoles({
        name: "",
        normalizedName: "",
        concurrencyStamp: "",
        institutionId: "EEB5FA29-D7C9-404C-0147-08D8185B4E74",
      });
    };
  };

  //aqui se setea valores de los inputs del formulario par poder escribir si o si debe estar
  const addValueTemp = (e) => {
    const { name, value } = e.target;

    setRoles((anterior) => ({
      ...anterior,
      [name]: value,
    }));
  };


  return (
    <Container component="main" maxWidth="md" justify="center"  style={style.iz}>
      <div style={style.paper}>
        <Typography component="h1" variant="h5">
          Registro de Nuevo Rol
        </Typography>
        <form style={style.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                name="name"
                variant="outlined"
                fullWidth
                label="Nombre del rol"
                value={roles.name}
                onChange={addValueTemp}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                name="normalizedName"
                variant="outlined"
                fullWidth
                label="Nombre estandarizado"
                value={roles.normalizedName}
                onChange={addValueTemp}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                name="concurrencyStamp"
                variant="outlined"
                fullWidth
                label="registro de concurrencia"
                value={roles.concurrencyStamp}
                onChange={addValueTemp}
              />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} md={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                style={style.submit}
                onClick={saveRoles}
              >
                Guardar Nuevo Rol
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default NewRoles;
