import React, { useState } from "react";
import { Container, Grid, Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";

import CardHeader from "@material-ui/core/CardHeader";
import Styles from "../Tool/Style";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { savePasiveConsumptionAction } from "../../actions/ConsumptionAction";
import { useStateValue } from "../../contexto/store";

const Add = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const classes = Styles();
  const [fromDate, setFromDate] = useState(new Date());

  const getInfoData = () => {
    const objectRequest = {
      fromDate: fromDate,
    };

    savePasiveConsumptionAction(objectRequest).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo exitosamente";
      } else {
        message += "Ya se agrego anteriormente";
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });
    });
  };

  return (
    <Container component="main" maxWidth="xl" justify="center">
      <Card className={classes.rootCar} style={{ padding: 40 }}>
        <CardHeader title={"Agregar consumo de Pasivos"} />

        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                value={fromDate}
                onChange={setFromDate}
                margin="normal"
                id="fecha-publicacion-id"
                label="Seleccione el mes para agregar"
                format="MM/yyyy"
                fullWidth
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                cancelLabel="Cancelar"
                okLabel="Seleccionar"
                views={["month", "year"]}
              />
            </MuiPickersUtilsProvider>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={getInfoData}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Add;
