import HttpCliente from "../servicios/HttpCliente";

export const getReportsList = (institucionId) => {
  return new Promise((resolve, reject) => {
    HttpCliente.get("/institutions/report", institucionId).then((response) => {
      resolve(response);
    });
  });
};

export const getListFinePdf = (pay) => {
  return new Promise((resolve) => {
    HttpCliente.getPdf("/reports/fine", pay).then((response) => {
      resolve(response);
    });
  });
};

export const getListPayPdf = (pay) => {
  return new Promise((resolve) => {
    HttpCliente.getPdf("/reports/pay", pay).then((response) => {
      resolve(response);
    });
  });
};

export const getListPayTodayPdf = (fromDate, toDate) => {
  return new Promise((resolve) => {
    HttpCliente.getPdfByDate("/reports/payToday", fromDate, toDate).then((response) => {
      resolve(response);
    });
  });
};


export const getListFineMonthPdf = (pay) => {
  return new Promise((resolve) => {
    HttpCliente.getPdf("/reports/findMonth", pay).then((response) => {
      resolve(response);
    });
  });
};

export const getListItemDatePdf = (fromDate, toDate) => {
  return new Promise((resolve) => {
    HttpCliente.getPdfByDate("/reports/findItemByDate", fromDate, toDate).then((response) => {
      resolve(response);
    });
  });
};


export const getListItemsPayToday = () => {
  return new Promise((resolve) => {
    HttpCliente.getOnly("/reports/getItemsPayToday").then((response) => {
      resolve(response);
    });
  });
};


export const getListItemsPay = (objetDate) => {
  return new Promise((resolve) => {
    HttpCliente.post("/reports/getItemsPay", objetDate).then((response) => {
      resolve(response);
    });
  });
};

export const getListAssociateExcel = () => {
  return new Promise((resolve) => {
    HttpCliente.getOnly("/reports/getAssociateExcel").then((response) => {
      resolve(response);
    });
  });
};

export const getMensualExcel = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJason("/reports/getMensualExcel", parameters).then((response) => {
      resolve(response);
    });
  });
};

export const getListMeasurerReader = (fromDate) => {
  return new Promise((resolve) => {
    HttpCliente.get("/reports/measurer", fromDate).then((response) => {
      resolve(response);
    });
  });
};


export const getListMeasurerCut = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJason("/reports/measurerCut", parameters).then((response) => {
      resolve(response);
    });
  });
};

export const getMeasurerCutPdf = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJasonPdf("/reports/measurerCutPdf", parameters).then((response) => {
      resolve(response);
    });
  });
};

export const getMeasurerPayPdf = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJasonPdf("/reports/measurerPayPdf", parameters).then((response) => {
      resolve(response);
    });
  });
};

export const getBucket = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJasonPdf("/reports/bucket", parameters).then((response) => {
      resolve(response);
    });
  });
};

export const yearConsumptions = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJasonPdf("/reports/yearConsumptions", parameters).then((response) => {
      resolve(response);
    });
  });
};

export const monthConsumptions = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJasonPdf("/reports/monthConsumptions", parameters).then((response) => {
      resolve(response);
    });
  });
};

export const fineAssociateConsumptions = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJasonPdf("/reports/fineAssociateConsumptions", parameters).then((response) => {
      resolve(response);
    });
  });
};

export const payAssociateConsumptions = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJasonPdf("/reports/payAssociateConsumptions", parameters).then((response) => {
      resolve(response);
    });
  });
};

export const transferAssociate = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJasonPdf("/reports/transferAssociate", parameters).then((response) => {
      resolve(response);
    });
  });
};

export const transferByDate = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJasonPdf("/reports/transferByDate", parameters).then((response) => {
      resolve(response);
    });
  });
};

export const measurerCurrent = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJasonPdf("/reports/measurerCurrent", parameters).then((response) => {
      resolve(response);
    });
  });
};
