import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import useStyles from './styles';
import Button from "@material-ui/core/Button";
import { getListMeasurerCut, getMeasurerCutPdf } from "../../actions/ReportAction";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { getListClassification } from "../../actions/ClassificationAction";

const MeasurerCut = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState('All');
    const [cant, setCant] = useState(4);
    const [datac, setDatac] = useState([]);

    

    useEffect(() => {
      const list = async () => {
        const objectRequest = {
          cant : 4,
          description : 'All'
        }
        const response = await getListMeasurerCut(objectRequest);
        setData(response.data);

        const responsec = await getListClassification();
        setDatac(responsec.data);
      };
      list();
    }, []);

    const generateReportPdf = () => {
      const listPdf = async () => {
        const objectRequest = {
          cant : cant,
          description : description
        }
        const res = await getMeasurerCutPdf(objectRequest);
        const file = new Blob([res.data], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);  
      };
  
      listPdf();
    };

    const generateList = () => {
      const objectRequest = {
        cant : cant,
        description : description
      }
      const list = async () => {      
        const response = await getListMeasurerCut(objectRequest);
        setData(response.data);
      };
      list();
      setOpen(false);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const openDialog = () => {
      setOpen(true);
    };

    const handleChange = (event) => {
      setDescription(event.target.value);
    };

    const handleChangeField = (event) => {
      setCant(event.target.value);
    };
  

  return (
    <>
    <Button
        id="btnItemDateR"
        variant="outlined"
        size="medium"
        color="primary"
        onClick={openDialog}
        style={{ marginTop: 6, marginLeft: 2 }}
      >
        Generar informe
      </Button>

      <Button
        id="btnItemDateP"
        variant="contained"
        size="medium"
        color="primary"
        onClick={generateReportPdf}
        style={{ marginTop: 6, marginLeft: 2 }}
      >
        Generar Pdf
      </Button>

      <TableContainer component={Paper}>
        <Table aria-label="caption table">
          <caption>
            Lista de medidores para corte
          </caption>
          <TableHead style={{backgroundColor:'primary'}}>
            <TableRow >
              <TableCell>Codigo medidor</TableCell>
              <TableCell align="left">Apellidos</TableCell>
              <TableCell align="left">Nombres</TableCell>
              <TableCell align="center">Cantidad de meses</TableCell>
              <TableCell align="center">Total deuda</TableCell>
              <TableCell align="center">Clasificacion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.code}>
                <TableCell component="th" scope="row">
                  {row.code}
                </TableCell>
                <TableCell align="left">{row.lastName}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="center">{row.cant}</TableCell>
                <TableCell align="center">{row.money}</TableCell>
                <TableCell align="center">{row.clasification}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4" align="center">
            Reporte Deudores
          </Typography>
        </DialogTitle>
        <DialogContent>
          
        <TextField
          label="Cantidad de meses de deuda"
          id="outlined-start-adornment"
          style={{width:'100%'}}
          value= {cant}
          className={clsx(classes.margin, classes.textField)}
          InputProps={{
            startAdornment: <InputAdornment position="start">Mayor e igual a : </InputAdornment>,
          }}
          variant="outlined"
          
          onChange={handleChangeField}
        />
        

        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Tipo de medidor
        </InputLabel>
        <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          value={description}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
        >
          <MenuItem value="">
            <em>Ninguno</em>
          </MenuItem>
          {datac.map((s) => (
            <MenuItem key={s.classificationId} value={s.description}>
              {s.description}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Clasificacion del medidor</FormHelperText>



        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={generateList} color="primary" variant="contained">
            Generar
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default MeasurerCut;
