import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {initialState} from './contexto/initialState';
import {StateProvider} from './contexto/store';
import {mainReducer} from './contexto/reducers';


const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <StateProvider initialState={initialState} reducer={mainReducer}>
        <App />
    </StateProvider>
  </StrictMode>,
  rootElement
);

serviceWorker.unregister();
