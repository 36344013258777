import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import { fineAssociateConsumptions } from "../../actions/ReportAction";

const FineAssociateConsumptions = () => {
  const [code, setCode] = useState("");
  const getInfoData = () => {
    const listPdf = async () => {
      const objectRequest = {
        code: code,
      };
      const res = await fineAssociateConsumptions(objectRequest);
      const file = new Blob([res.data], {
        type: "application/pdf",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };

    listPdf();
  };

  const addValueTemp = (e) => {
    setCode(e.target.value);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" align="center">
          Cuentas por pagar del consumo de agua potable
        </Typography>

        <TextField
          name="code"
          variant="outlined"
          fullWidth
          label="Codigo Medidor"
          value={code}
          onChange={addValueTemp}
        />
      </CardContent>

      <CardActions>
        <Button
          onClick={getInfoData}
          color="primary"
          variant="contained"
          fullWidth
        >
          Generar
        </Button>
      </CardActions>
    </Card>
  );
};

export default FineAssociateConsumptions;
