import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useStateValue } from "../../contexto/store";
import {
  saveConsumptionAction,
  getListConsumptionAction,
  getLastConsumptionAction,
  deleteConsumptionAction,
  updateConsumptionAction,
  saveConsumptionFineAction,
  saveConsumptionPayAction,
  getListConsumptionPdf,
  getListConsumptionPayPdf,
} from "../../actions/ConsumptionAction";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Container } from "@material-ui/core";

import PayTable from "./PayTable";
import Typography from "@material-ui/core/Typography";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import Tooltip from "@material-ui/core/Tooltip";

const ListTableConsumption = ({ valueM, valueP, name, code }) => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [dateSelect, setDateSelect] = useState(new Date());

  const [totalReceivedPay, setTotalReceivedPay] = useState(0);
  const [totalReturneday, setTotalReturneday] = useState(0);

  const [dataPrevius, setDataPrevius] = useState(0);

  useEffect(() => {
    const list = async () => {
      const response = await getListConsumptionAction(valueM);
      setData(response.data);
      const last = await getLastConsumptionAction(valueM);

      setDataPrevius(last.data.present);
    };
    list();
  }, [valueM]);

  const columns = [
    { title: "ConsumptionId", field: "consumptionId", hidden: true },
    {
      title: "Mes",
      field: "dateConsumption",
      // width: 500,
      editable: "onAdd",
      headerStyle: {
        minWidth: 100,
        maxWidth: 140,
        padding: 0,
        // margin:0,
      },
      cellStyle: {
        minwidth: 100,
        maxWidth: 140,
        padding: 0,
        // margin:0,
      },
      render: (rowData) => (
        <Typography>{dateBuild(rowData.dateConsumption)}</Typography>
      ),

      editComponent: (props) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DatePicker
            // clearable
            views={["month", "year"]}
            format="MM/yyyy"
            value={dateSelect}
            onChange={setDateSelect}
            cancelLabel="cancelar"
            clearLabel="limpiar"
            okLabel="seleccionar"
          />
        </MuiPickersUtilsProvider>
      ),
    },
    { title: "Es Inicio?", field: "isStart", type: "boolean", hidden: true },
    { title: "Inicio", field: "start", hidden: true },
    {
      title: "Actual",
      field: "present",
      headerStyle: {
        minWidth: 40,
        maxWidth: 50,
        padding: 0,
        textAlign: "center",
        // margin:0,
      },
      cellStyle: {
        minwidth: 40,
        maxWidth: 50,
        padding: 0,
        textAlign: "center",
        // margin:0,
      },
    },
    {
      title: "Consumo (m³)",
      field: "totalConsumption",
      editable: "never",
      type: "numeric",
      headerStyle: {
        minWidth: 130,
        // maxWidth: 100,
        padding: 0,
        textAlign: "center",

        // margin:0,
      },
      cellStyle: {
        minwidth: 130,
        // maxWidth: 100,
        padding: 0,
        textAlign: "center",

        // margin:0,
      },
    },
    {
      title: "Anterior",
      field: "previusConsumption",
      initialEditValue: dataPrevius,
      editable: "never",
      headerStyle: {
        minWidth: 40,
        maxWidth: 70,
        textAlign: "center",
        padding: 0,
        // margin:0,
      },
      cellStyle: {
        minwidth: 40,
        maxWidth: 70,
        textAlign: "center",
        padding: 0,
        // margin:0,
      },
    },

    {
      title: "Monto",
      field: "price",
      editable: "never",
      type: "currency",
      currencySetting: { locale: "es-BO", currencyCode: "BOB" },
      headerStyle: {
        paddingRight: 10,
      },
      cellStyle: {
        paddingRight: 10,
        // margin:0,
      },
    },

    { title: "Detalle Inicio", field: "detailStart", hidden: true },
  ];

  const dateBuild = (date) => {
    const newDate = new Date(date);
    const month = newDate.toLocaleDateString("default", { month: "long" });
    return month + " " + newDate.getFullYear();
  };

  const changeDataInt = (value) => {
    if (null == value || "0" == value) {
      return 0;
    }
    return parseInt(value);
  };

  const calculateValue = (previus, present) => {
    return calculateConsumption(previus, present) * valueP;
  };

  const calculateConsumption = (previus, present) => {
    return changeDataInt(present) - changeDataInt(previus);
  };

  const handleRowAdd = (consumption, resolve) => {
    if (
      changeDataInt(consumption.present) >=
      changeDataInt(consumption.previusConsumption)
    ) {
      const objectConsumption = {
        dateConsumption: dateSelect,
        previusConsumption: changeDataInt(consumption.previusConsumption),
        start: 0,
        present: changeDataInt(consumption.present),
        price: calculateValue(
          consumption.previusConsumption,
          consumption.present
        ),
        totalConsumption: calculateConsumption(
          consumption.previusConsumption,
          consumption.present
        ),
        isStart: false,
        detailStart: " ",
        measurerId: valueM,
      };

      saveConsumptionAction(objectConsumption).then((response) => {
        let message = "";

        if (response.status === 200) {
          const listPdf = async () => {
            const res = await getListConsumptionPdf(response.data);
            const file = new Blob([res.data], {
              type: "application/pdf",
            });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          };
          listPdf();

          message += "Se guardo exitosamente el consumo";
        } else {
          message += "Errores :" + Object.keys(response.data.errors);
        }

        dispatch({
          type: "OPEN_SNACKBAR",
          openMensaje: {
            open: true,
            mensaje: message,
          },
        });

        const list = async () => {
          const response = await getListConsumptionAction(valueM);
          setData(response.data);
          const last = await getLastConsumptionAction(valueM);
          setDataPrevius(last.data.present);
        };
        list();
        resolve();
      });
    } else {
      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: "Lectura incorrecta",
        },
      });

      resolve();
    }
  };

  const handleRowDelete = (oldData, resolve) => {
    deleteConsumptionAction(oldData.consumptionId).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se elimino exitosamente el consumo";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const list = async () => {
        const response = await getListConsumptionAction(valueM);
        setData(response.data);
        const last = await getLastConsumptionAction(valueM);
        setDataPrevius(last.data.present);
      };
      list();
      resolve();
    });
  };

  const handleRowUpdate = (newData, oldData, resolve) => {
    /*if(newData.email === "" || validateEmail(newData.email) === false){
      errorList.push("Please enter a valid email")
    }*/

    if (
      changeDataInt(newData.present) >=
      changeDataInt(newData.previusConsumption)
    ) {
      const updateConsumption = {
        dateConsumption: newData.dateConsumption,
        previusConsumption: changeDataInt(newData.previusConsumption),
        start: changeDataInt(newData.start),
        present: changeDataInt(newData.present),
        price: calculateValue(newData.previusConsumption, newData.present),
        totalConsumption: calculateConsumption(
          newData.previusConsumption,
          newData.present
        ),
        isStart: newData.isStart,
        detailStart: newData.detailStart,
      };

      updateConsumptionAction(oldData.consumptionId, updateConsumption).then(
        (response) => {
          let message = "";

          if (response.status === 200) {
            message += "Se guardo cambios exitosamente";
          } else {
            message += "Errores :" + Object.keys(response.data.errors);
          }

          dispatch({
            type: "OPEN_SNACKBAR",
            openMensaje: {
              open: true,
              mensaje: message,
            },
          });

          const list = async () => {
            const response = await getListConsumptionAction(valueM);
            setData(response.data);
            const last = await getLastConsumptionAction(valueM);
            setDataPrevius(last.data.present);
          };
          list();
          resolve();
        }
      );
    } else {
      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: "Lectura incorrecta",
        },
      });

      resolve();
    }
  };

  const handleSelection = (rows) => {
    //alert("You selected " + rows.length + " rows")

    //setVisible(true);

    if (rows.length > 0) {
      setList(rows);

     /* rows.map((row) => {
        const fineObject = {
          ConsumptionId: row.consumptionId,
        };
        saveConsumptionFineAction(fineObject);
      });*/
      setVisible(true);
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handlePay = () => {
    const dataList = [];

    for (let i = 0; i < list.length; i++) {
      dataList.push(list[i].consumptionId);
    }

    const objectPay = {
      receivedPay: parseFloat(totalReceivedPay),
      returnedPay: parseFloat(totalReturneday),
      consumptionId: dataList.toString(),
      institutionId: sesionUsuario.usuario.institutionId,
    };

    saveConsumptionPayAction(objectPay).then((response) => {
      let message = "";

      if (response.status === 200) {
        /* console.log(dataList.toString());
        const listPdf = async (element) => {
          const res = await getListConsumptionPayPdf(element);
          const file = new Blob([res.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        };

        dataList.map((row) => {
          listPdf(row);
        });*/

        const listPdf = async () => {
          const res = await getListConsumptionPayPdf(dataList.toString());
          const file = new Blob([res.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        };

        listPdf();

        message += "Se guardo exitosamente el Pago";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      setVisible(false);

      const list = async () => {
        const response = await getListConsumptionAction(valueM);
        setData(response.data);
      };
      list();
    });
  };

  return (
    <div>
      <MaterialTable
        localization={{
          body: {
            editRow: {
              deleteText: "Esta seguro de eliminar el consumo?",
              saveTooltip: "Guardar",
              cancelTooltip: "Cancelar",
            },
            emptyDataSourceMessage: " sin deudas pendientes...",
            addTooltip: "Agregar",
            grouping: { placeholder: "haber" },
            deleteTooltip: "Eliminar",
            editTooltip: "Editar",
          },
          toolbar: {
            nRowsSelected: "Meses a pagar: {0}",
          },
          header: { actions: "Acciones" },
        }}
        options={{
          exportButton: false,
          actionsColumnIndex: 0,
          selection: false,
          padding: "dense",
          isFreeAction: true,

          search: false,
          paging: false,
          headerStyle: {
            backgroundColor: "#e8f5e9",
            color: "#000",
          },
        }}
        title="CONSUMO"
        columns={columns}
        data={data}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              handleRowAdd(newData, resolve);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              handleRowUpdate(newData, oldData, resolve);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve);
            }),
        }}
        onSelectionChange={(rows) => handleSelection(rows)}
        style={{
          marginLeft: 2,
          marginRight: 2,
          marginBottom: 5,
          borderRadius: 20,
        }}
      />

      {visible && (
        <Dialog
          open={visible}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Typography component="p" align="center">
              DETALLES DE PAGO DEL MEDIDOR:
              <b> {code} </b>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography component="p" style={{ marginBottom: 10 }}>
              <b> Socio : </b>
              {name}
            </Typography>

            <PayTable
              value={list}
              setTotalReceivedPay={setTotalReceivedPay}
              setTotalReturneday={setTotalReturneday}
            ></PayTable>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
            <Button onClick={handlePay} color="primary">
              Pagar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default ListTableConsumption;
