import HttpCliente from "../servicios/HttpCliente";

export const saveEntryAction = (entry) => {
  return new Promise((resolve, eject) => {
    HttpCliente.post("/entries", entry)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getEntriesList = (institucionId) => {
  return new Promise((resolve, reject) => {
    HttpCliente.get("/entries/list", institucionId).then((response) => {
      resolve(response);
    });
  });
};

export const getEntriesInvoce = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJasonPdf("/entries/invoce", parameters).then((response) => {
      resolve(response);
    });
  });
};
