import HttpCliente from "../servicios/HttpCliente";

export const saveMeasurerAction = (measurer) => {
  return new Promise((resolve) => {
    HttpCliente.post("/measurers", measurer)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getListMeasurerAction = (associateId) => {
  return new Promise((resolve) => {
    HttpCliente.get("/measurers/list", associateId).then((response) => {
      resolve(response);
    });
  });
};

export const deleteMeasurerAction = (measurerId) => {
  return new Promise((resolve) => {
    HttpCliente.delete('/measurers/delete/' + measurerId).then((response) => {
      resolve(response);
    });
  });
};

export const updateMeasurerAction = (measurerId, measurer) => {
  return new Promise((resolve) => {
    HttpCliente.put('/measurers/' + measurerId, measurer).then((response) => {
      resolve(response);
    });
  });
};
