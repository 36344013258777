import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { getListPayTodayPdf } from "../../actions/ReportAction";
import { Card, CardActions, CardContent } from "@material-ui/core";

const ListAllReport = () => {
  const [fromDateToday, setFromDateToday] = useState(new Date());
  const [toDateToday, setToDateToday] = useState(new Date());

  const registerNewDataToday = () => {
    const listPdf = async (fromDateToday, toDateToday) => {
      const res = await getListPayTodayPdf(fromDateToday, toDateToday);
      const file = new Blob([res.data], {
        type: "application/pdf",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };

    listPdf(fromDateToday, toDateToday);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h4" align="center">
          Cobros por fechas
        </Typography>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            value={fromDateToday}
            onChange={setFromDateToday}
            margin="normal"
            id="fecha-publicacion-id"
            label="Fecha de inicio"
            format="dd/MM/yyyy"
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            cancelLabel="Cancelar"
            okLabel="Seleccionar"
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            value={toDateToday}
            onChange={setToDateToday}
            margin="normal"
            id="fecha-publicacion-idto"
            label="Fecha de fin"
            format="dd/MM/yyyy"
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            cancelLabel="Cancelar"
            okLabel="Seleccionar"
          />
        </MuiPickersUtilsProvider>
      </CardContent>

      <CardActions>
        <Button
          onClick={registerNewDataToday}
          color="primary"
          variant="contained"
          fullWidth
        >
          Generar
        </Button>
      </CardActions>
    </Card>
  );
};

export default ListAllReport;
