import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { getListItemDatePdf } from "../../actions/ReportAction";

import { Card, CardActions, CardContent } from "@material-ui/core";

const ItemsPay = () => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const generateReportPdf = () => {
    const listPdf = async (fromDate, toDate) => {
      const res = await getListItemDatePdf(fromDate, toDate);
      const file = new Blob([res.data], {
        type: "application/pdf",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };

    listPdf(fromDate, toDate);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h4" align="center">
          Cobros por item 
        </Typography>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            value={fromDate}
            onChange={setFromDate}
            margin="normal"
            id="fecha-publicacion-id"
            label="Fecha de inicio"
            format="dd/MM/yyyy"
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            cancelLabel="Cancelar"
            okLabel="Seleccionar"
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            value={toDate}
            onChange={setToDate}
            margin="normal"
            id="fecha-publicacion-idto"
            label="Fecha de fin"
            format="dd/MM/yyyy"
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            cancelLabel="Cancelar"
            okLabel="Seleccionar"
          />
        </MuiPickersUtilsProvider>
      </CardContent>

      <CardActions>
        <Button
          id="btnItemDateR"
          variant="contained"
          size="medium"
          color="primary"
          fullWidth
          onClick={generateReportPdf}
          style={{ marginTop: 6, marginLeft: 2 }}
        >
          Generar reporte
        </Button>
      </CardActions>
    </Card>
  );
};

export default ItemsPay;
