import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ListAllReport from "./ListAllReport";
import ItemsPay from "./ItemsPay";

const usesStyle = makeStyles({
  griContainer : {
    padding: '20px'
  }
});

const Charges = () => {
  const classes = usesStyle();
  return (
    <Grid container spacing={2} justify="center" className={classes.griContainer}>
      <Grid item xs={12} sm={6} md={4}>
       <ListAllReport></ListAllReport>
      </Grid>  
      <Grid item xs={12} sm={6} md={4}>
       <ItemsPay></ItemsPay>
      </Grid>
    </Grid>
  );
};

export default Charges;
