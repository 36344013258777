import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  getListPayPdf,
  getListFinePdf,
  getListPayTodayPdf,
  getListItemDatePdf,
} from "../../actions/ReportAction";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Paytoday = () => {
  const [openToday, setOpenToday] = useState(false);
  const [fromDateToday, setFromDateToday] = useState(new Date());
  const [toDateToday, setToDateToday] = useState(new Date());
  const classes = useStyles();

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
  ];

  const handlePayToday = () => {
    setOpenToday(true);
  };

  const handleCloseToday = () => {
    setOpenToday(false);
  };

  const registerNewDataToday = () => {
    const listPdf = async (fromDateToday, toDateToday) => {
      const res = await getListPayTodayPdf(fromDateToday, toDateToday);
      const file = new Blob([res.data], {
        type: "application/pdf",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      setOpenToday(false);
    };

    listPdf(fromDateToday, toDateToday);
  };

  return (
    <>
      <Button
        id="btnToday"
        variant="outlined"
        size="medium"
        color="primary"
        onClick={handlePayToday}
        style={{ marginTop: 6, marginLeft: 2 }}
      >
        Pagos de hoy
      </Button>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="caption table">
          {/* <caption>A basic table example with a caption</caption> */}
          <TableHead>
            <TableRow>
              <TableCell>Dessert (100g serving)</TableCell>
              <TableCell align="right">Calories</TableCell>
              <TableCell align="right">Fat&nbsp;(g)</TableCell>
              <TableCell align="right">Carbs&nbsp;(g)</TableCell>
              <TableCell align="right">Protein&nbsp;(g)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openToday}
        onClose={handleCloseToday}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4" align="center">
            Reporte por Item-Fecha
          </Typography>
        </DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              value={fromDateToday}
              onChange={setFromDateToday}
              margin="normal"
              id="fecha-publicacion-id"
              label="Fecha de inicio"
              format="dd/MM/yyyy"
              fullWidth
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              cancelLabel="Cancelar"
              okLabel="Seleccionar"
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              value={toDateToday}
              onChange={setToDateToday}
              margin="normal"
              id="fecha-publicacion-idto"
              label="Fecha de fin"
              format="dd/MM/yyyy"
              fullWidth
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              cancelLabel="Cancelar"
              okLabel="Seleccionar"
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseToday} color="primary" variant="outlined">
            Cancelar
          </Button>
          <Button
            onClick={registerNewDataToday}
            color="primary"
            variant="contained"
          >
            Generar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Paytoday;
