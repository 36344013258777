import React from 'react';
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Tooltip } from '@material-ui/core';

export const MenuReader = [    
    {
        title:"Lecturar",
        icon:
        <Tooltip title='lecturar'>
        <FormatColorFillIcon/>
        </Tooltip>,
        link: "/RoleReader/list",
    }, 
    {
        title:"Medidores sin consumo",
        icon:
        <Tooltip title="Medidores sin consumo">
        <AssessmentIcon/>
        </Tooltip>,
        link: "/reports/measurer"
    },   
];


