import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { yearConsumptions } from "../../actions/ReportAction";

const YearConsumptions = () => {
  const [fromDateToday, setFromDateToday] = useState(new Date());
  const getInfoData = () => {
    const listPdf = async () => {
      const objectRequest = {
        fromDate: fromDateToday,
      };
      const res = await yearConsumptions(objectRequest);
      const file = new Blob([res.data], {
        type: "application/pdf",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };

    listPdf();
  };
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" align="center">
        Consumo anual
        </Typography>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            value={fromDateToday}
            onChange={setFromDateToday}
            views={["year"]}
            margin="normal"
            id="fecha-publicacion-id"
            label="Introducir año"
            format="yyyy"
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            cancelLabel="Cancelar"
            okLabel="Seleccionar"
          />
        </MuiPickersUtilsProvider>
      </CardContent>

      <CardActions>
        <Button
          onClick={getInfoData}
          color="primary"
          variant="contained"
          fullWidth
        >
          Generar
        </Button>
      </CardActions>
    </Card>
  );
};

export default YearConsumptions;
