import React, { useState, useEffect } from "react";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { Grid } from "@material-ui/core";
import person from "../../person.jpg";
import ListTableConsumption from "./ListTableConsumption";
import Styles from "../Tool/Style";
import { useStateValue } from "../../contexto/store";
import { getMeasurerAssociateAction } from "../../actions/ConsumptionAction";

const Index = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const classes = Styles();
  const [objetAssociate, setObjetAssociate] = useState({
    measurerId: "",
    code: "",
    lastName: "",
    name: "",
    description: "",
    price: "",
  });

  useEffect(() => {
    // console.log(sesionUsuario);
    const list = async () => {
      const response = await getMeasurerAssociateAction(
        sesionUsuario.usuario.username
      );
      //console.log(response.data);
      setObjetAssociate({
        measurerId : response.data.measurerId,
        code : response.data.code,
        lastName: response.data.associate.lastName,
        name: response.data.associate.name,
        description: response.data.classification.description,
        price : response.data.classification.price
      });
    };
    list();
  }, []);

  return (
    <Card className={classes.rootCar}>
      <CardHeader
        avatar={
          <Avatar
            aria-label="recipe"
            className={classes.avatarC}
            style={{ backgroundColor: "#000" }}
          >
            {objetAssociate.code.substring(0, 5)}
          </Avatar>
        }
        titleTypographyProps={{ variant: "h6" }}
        subheaderTypographyProps={{ variant: "h6" }}
        title={"Codigo Medidor : " + objetAssociate.code}
        subheader={
          objetAssociate.lastName +
          " " +
          objetAssociate.name
        }
      />

      <Grid container spacing={1}>
        <Grid
          container
          item
          xs={12}
          md={3}
          direction="column"
          alignItems="center"
        >
          <img
            src={person}
            alt={objetAssociate.name}
            className={classes.poster}
            aling="center"
          />
          <Typography className={classes.LePri}>
            {objetAssociate.description}
          </Typography>
          <Typography>
            Precio Cubo : {objetAssociate.price}
          </Typography>
        </Grid>

        <Grid item xs={12} md={9}>
         {objetAssociate.measurerId !== "" && <ListTableConsumption
            key={objetAssociate.measurerId}
            valueM={objetAssociate.measurerId}
            name={
              objetAssociate.lastName +
              " " +
              objetAssociate.name
            }
            code={objetAssociate.code}
          />
        }
        </Grid>
      </Grid>
    </Card>
  );
};

export default Index;
