import HttpCliente from "../servicios/HttpCliente";

export const saveAssociateAction = (associate) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/associates", associate)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getListAssociate = (objectPaged) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/associates/list", objectPaged).then((response) => {
      resolve(response);
    });
  });
};

export const getListAssociateSearch = (objectText) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/associates/search", objectText).then((response) => {
      resolve(response);
    });
  });
};

export const deleteAssociate = (id) => {
  return new Promise((resolve, reject) => {
    HttpCliente.delete('/associates/delete/' + id).then((response) => {
      resolve(response);
    });
  });
};

export const updateAssociate = (id, associate) => {
  return new Promise((resolve, reject) => {
    HttpCliente.put('/associates/' + id, associate).then((response) => {
      resolve(response);
    });
  });
};
