import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useStateValue } from "../../contexto/store";
import {
  getListMeasurerAction,
  deleteMeasurerAction,
  updateMeasurerAction,
  saveMeasurerAction,
} from "../../actions/MeasurerAction";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { set } from "date-fns/fp";

const ListTableMeasurer = ({ value, datac }) => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [associateId, setAssociateId] = useState(value);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const listMeasurer = async () => {
      const response = await getListMeasurerAction(associateId);
      setData(response.data);
    };
    listMeasurer();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };


  const columns = [
    { title: "measurerId", field: "measurerId", hidden: true },
    {
      title: "Codigo",
      field: "code",
      cellStyle: {
        minWidth: 50,
      },
      headerStyle: {
        minWidth: 50,
      },
    },
    {
      title: "Numero",
      field: "address",
      cellStyle: {
        minWidth: 90,
      },
      headerStyle: {
        minWidth: 90,
      },
    },
    { title: "Map", field: "map", hidden: true },
    { title: "AssociateId", field: "associateId", hidden: true },
    {
      title: "Clasificación",
      field: "classificationId",
      render: (rowData) => (
        <Typography>{rowData.classification.description}</Typography>
      ),

      editComponent: (props) => (
        <Select
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        open={open}
        style={{maxWidth:80}}
        onOpen={handleOpen}
        value={props.value || ''}
        onChange={e => {
          props.onChange(e.target.value);
         setOpen(false);
        }}

        >
          {datac.map((s) => (
            <MenuItem key={s.classificationId} value={s.classificationId}>
              {s.description}
            </MenuItem>
          ))}
        </Select>
      ),
      cellStyle: {
        width: 95,
        maxWidth: 95,
      },
      headerStyle: {
        width: 95,
        maxWidth: 95,
      },
    }
  ];

  const handleRowAdd = (measurer, resolve) => {
    const objectMeasurer = {
      code: measurer.code,
      address: measurer.address,
      map: " ",
      associateId: associateId,
      classificationId: measurer.classificationId,
    };

    saveMeasurerAction(objectMeasurer).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo exitosamente el nuevo medidor";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      };

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const listMeasurer = async () => {
        const response = await getListMeasurerAction(associateId);
        setData(response.data);
      };
      listMeasurer();
      resolve();
    });
  };

  const handleRowDelete = (oldData, resolve) => {
    deleteMeasurerAction(oldData.measurerId).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se elimino exitosamente el medidor";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const listMeasurer = async () => {
        const response = await getListMeasurerAction(associateId);
        setData(response.data);
      };
      listMeasurer();
      resolve();
    });
  };

  const handleRowUpdate = (newData, oldData, resolve) => {
    /*if(newData.email === "" || validateEmail(newData.email) === false){
      errorList.push("Please enter a valid email")
    }*/

    const updateMeasurerObject = {
      messuarerId: oldData.measurerId,
      code: newData.code,
      address: newData.address,
      classificationId: newData.classificationId,
      map: oldData.map,
    };

    updateMeasurerAction(oldData.measurerId, updateMeasurerObject).then(
      (response) => {
        let message = "";

        if (response.status === 200) {
          message += "Se guardo cambios exitosamente";
        } else {
          message += "Errores :" + Object.keys(response.data.errors);
        }

        dispatch({
          type: "OPEN_SNACKBAR",
          openMensaje: {
            open: true,
            mensaje: message,
          },
        });

        const listMeasurer = async () => {
          const response = await getListMeasurerAction(associateId);
          setData(response.data);
        };
        listMeasurer();
        resolve();
      }
    );
  };

  return (
    <MaterialTable
      localization={{
        body: {
          editRow: { deleteText: "Esta seguro de eliminar el medidor?" },
        },
        header: { actions: "Aciones" },
      }}
      options={{
        exportButton: false,
        actionsColumnIndex: -1,
        padding: "dense",
        paging: false,
        search: false,
        headerStyle: {
          backgroundColor: "#e8f5e9",
          color: "#000",
        },
      }}
      title={"MEDIDORES"}
      columns={columns}
      data={data}
      editable={(sesionUsuario.usuario.rolName != "Secretary") && {
        onRowAdd: (newData) =>
          new Promise((resolve) => {
            handleRowAdd(newData, resolve);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            handleRowUpdate(newData, oldData, resolve);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            handleRowDelete(oldData, resolve);
          }),
      }}
      style={{
        marginLeft: 2,
        marginRight: 2,
        marginBottom: 5,
        borderRadius: 20,
      }}
    />
  );
};

export default ListTableMeasurer;
