import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import Style from "../Tool/Style";
import {
  getExpenseInvoce,
  saveExpenseAction,
} from "../../actions/ExpenseAction";
import { useStateValue } from "../../contexto/store";

const NewExpense = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();

  const [expense, setExpense] = useState({
    name: "",
    description: "",
    price: null,
    controlCode: "",
  });

  const saveExpense = (e) => {    
    e.preventDefault();

    const objectExpense = {
      name: expense.name,
      description: expense.description,
      price: parseInt(expense.price || null),
      controlCode: expense.controlCode,
      institutionId: sesionUsuario.usuario.institutionId,
      userModified: sesionUsuario.usuario.id,
    };

    saveExpenseAction(objectExpense).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo exitosamente el nuevo Egreso";

        ///////
        const listPdf = async () => {
          const objectInvoce = {
            expenseId: response.data,
          };
          const res = await getExpenseInvoce(objectInvoce);
          const file = new Blob([res.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        };    
    
        listPdf();
        ////


        resetForm();
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });
    });
  };

  
  const resetForm = () => {
    setExpense({
      name: "",
      description: "",
      price: "",
      controlCode: "",
    });
  };

  const addValueTemp = (e) => {
    const { name, value } = e.target;

    setExpense((anterior) => ({
      ...anterior,
      [name]: value,
    }));
  };

  // const [userModified, setuserModified] = React.useState("");

  // const handleChange = (event) => {
  //   setuserModified(event.target.value);
  // };

  const classes = Style();

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Registro de Nuevo Egreso
        </Typography>
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                required
                name="name"
                variant="outlined"
                fullWidth
                label="Nombre"
                value={expense.name}
                onChange={addValueTemp}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                required
                name="description"
                variant="outlined"
                fullWidth
                label="descripcion"
                value={expense.description}
                onChange={addValueTemp}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                required
                name="controlCode"
                variant="outlined"
                fullWidth
                label="codigo de control"
                value={expense.controlCode}
                onChange={addValueTemp}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                required
                name="price"
                variant="outlined"
                fullWidth
                label="monto"
                value={expense.price}
                onChange={addValueTemp}
              />
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Grid item xs={12} md={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}
                onClick={saveExpense}
              >
                Guardar Egreso
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default NewExpense;
