import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  getListMeasurerReader,
} from "../../actions/ReportAction";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const MeasurerReader = () => {
  const [data, setData] = useState([]);
  const [openToday, setOpenToday] = useState(false);
  const [fromDateToday, setFromDateToday] = useState(new Date());
  const classes = useStyles();

  const handlePayToday = () => {
    setOpenToday(true);
  };

  const handleCloseToday = () => {
    setOpenToday(false);
  };

  const getInfoData = () => {
    const listPdf = async (fromDateToday) => {
      const res = await getListMeasurerReader(fromDateToday);
      setData(res.data);
      setOpenToday(false);
    };

    listPdf(fromDateToday);
  };

  return (
    <>
      <Button
        id="btnToday"
        variant="outlined"
        size="medium"
        color="primary"
        onClick={handlePayToday}
        style={{ marginTop: 6, marginLeft: 2 }}
      >
        Ver medidores
      </Button>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="caption table">
          <caption>Lista de todos los medidores sin lectura para este mes </caption>
          <TableHead>
            <TableRow>
              <TableCell>Codigo medidor</TableCell>
              <TableCell align="right">Apellidos</TableCell>
              <TableCell align="right">Nombres</TableCell>
              <TableCell align="right">CI</TableCell>
              <TableCell align="right">Telefono</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.code}>
                <TableCell component="th" scope="row">
                  {row.code}
                </TableCell>
                <TableCell align="right">{row.lastName}</TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.ci}</TableCell>
                <TableCell align="right">{row.phoneNumber}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openToday}
        onClose={handleCloseToday}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4" align="center">
           Medidores sin Consumo
          </Typography>
        </DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              value={fromDateToday}
              onChange={setFromDateToday}
              views={["month", "year"]}
              margin="normal"
              id="fecha-publicacion-id"
              label="Mes de consumo"
              format="MM/yyyy"
              fullWidth
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              cancelLabel="Cancelar"
              okLabel="Seleccionar"
            />
          </MuiPickersUtilsProvider>

         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseToday} color="primary" variant="outlined">
            Cancelar
          </Button>
          <Button
            onClick={getInfoData}
            color="primary"
            variant="contained"
          >
            Generar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MeasurerReader;
