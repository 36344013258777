import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { Grid, Button, Select } from "@material-ui/core";
import { useStateValue } from "../../contexto/store";
import { getEntriesList } from "../../actions/EntryAction";
import { getExpensesList } from "../../actions/ExpenseAction";
import { getReportsList } from "../../actions/ReportAction";

const EntryList = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [institutionId, setInstitutionId] = useState(
    sesionUsuario.usuario.institutionId
  );

  const columns = [
    { title: "Nombre", field: "name" },
    { title: "Descripcion", field: "description" },
    { title: "Monto", field: "price" },
    { title: "Codigo de control", field: "controlCode" },
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    reportsList();
  }, []);

  const reportsList = async () => {
    const response = await getReportsList(institutionId);
    setData([]);
  };

  const entryList = (e) => {
    e.preventDefault();

    const entriesList = async () => {
      const response = await getEntriesList(institutionId);
      setData(response.data);
    };
    entriesList();
  };

  const expenseList = (e) => {
    e.preventDefault();

    const expensesList = async () => {
      const response = await getExpensesList(institutionId);
      setData(response.data);
    };
    expensesList();
  };

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={6} >
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            elevation="1"
            style={{marginBottom:5}}
            color="primary"
            size="large"
            onClick={entryList}
          >
            INGRESOS
          </Button>
        </Grid>

        <Grid item xs={6} >
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            elevation="1"
            style={{marginBottom:5}}
            color="primary"
            size="large"
            onClick={expenseList}
          >
            EGRESOS
          </Button>
        </Grid>

        {/* <Grid item xs={6} md={4}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={reportsList}
          >
            Reportes
          </Button>
        </Grid> */}
      </Grid>

      <MaterialTable
        title="LISTA DE REGISTROS"
        options={{
          headerStyle: {
            backgroundColor: "#e8f5e9",
            color: "#000",
          },
          searchFieldStyle: {
            backgroundColor: "#fff",
            color: "#000",
            borderRadius: 9,
          },
          paging:false
        }}
        columns={columns}
        data={data}
        style={{
          borderRadius: 20,
          boxShadow: "-5px 5px 6px 3px rgba(0, 0, 0, .75)",
          backgroundImage: 'linear-gradient(45deg, #1769aa, #c8e6c9, #1769aa',
        }}
      />
    </div>
  );
};

export default EntryList;
