import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import style from "../Tool/Style";
import { saveAssociateAction } from "../../actions/AssociateAction";
import { useStateValue } from "../../contexto/store";

const NewAssociate = () => {
  //traer variable global de los mensajes y usuario aunque no lo use por el momento
  const [{ sesionUsuario }, dispatch] = useStateValue();

  //costante creacion de variables de objeto socio para estado
  const [associate, setAssociate] = useState({
    name: "",
    lastName: "",
    phoneNumber: null,
    address: "",
    map: "",
    ci: null,
    institutionId: "",
  });

  const saveAssociate = (e) => {
    e.preventDefault();

    //constante para del objeto socio para guardar en la BD seteados los valores del formulario
    const objectAssociate = {
      name: associate.name,
      lastName: associate.lastName,
      phoneNumber: parseInt(associate.phoneNumber || null),
      address: associate.address,
      map: associate.map,
      ci: parseInt(associate.ci || null),
      institutionId: sesionUsuario.usuario.institutionId,
    };

    //llamar al Action socio
    saveAssociateAction(objectAssociate).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo exitosamente el nuevo socio";
        resetForm();
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });
    });

    const resetForm = () => {
      setAssociate({
        name: "",
        lastName: "",
        phoneNumber: "",
        address: "",
        map: "",
        ci: "",
        institutionId: "",
      });
    };
  };

  //aqui se setea valores de los inputs del formulario par poder escribir si o si debe estar
  const addValueTemp = (e) => {
    const { name, value } = e.target;

    setAssociate((anterior) => ({
      ...anterior,
      [name]: value,
    }));
  };

  return (
    <Container component="main" maxWidth="md" justify="center"  style={style.iz}>
      <div style={style.paper}>
        <Typography component="h1" variant="h5">
          Registro de Nuevo socio
        </Typography>
        <form style={style.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                name="name"
                variant="outlined"
                fullWidth
                label="Nombre"
                value={associate.name}
                onChange={addValueTemp}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                name="lastName"
                variant="outlined"
                fullWidth
                label="Apellidos"
                value={associate.lastName}
                onChange={addValueTemp}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                name="address"
                variant="outlined"
                fullWidth
                label="Direccion"
                value={associate.address}
                onChange={addValueTemp}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                name="map"
                variant="outlined"
                fullWidth
                label="Mapa"
                value={associate.map}
                onChange={addValueTemp}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="phoneNumber"
                variant="outlined"
                fullWidth
                label="Telefono"
                value={associate.phoneNumber}
                onChange={addValueTemp}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="ci"
                variant="outlined"
                fullWidth
                label="CI"
                value={associate.ci}
                onChange={addValueTemp}
              />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} md={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                style={style.submit}
                onClick={saveAssociate}
              >
                Guardar Nuevo Socio
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default NewAssociate;
