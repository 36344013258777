import React from 'react';
import { Tooltip } from '@material-ui/core';
import InvertColorsOffIcon from '@material-ui/icons/InvertColorsOff';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ReceiptIcon from '@material-ui/icons/Receipt';

export const MenuReport = [  
    {
        title:"Cobros",
        icon:
        <Tooltip title="Cobros">
        <ReceiptIcon/>
        </Tooltip>,
        link: "/reports/charges"
    },  
    {
        title:"Consumos",
        icon:
        <Tooltip title="Consumos">
        <ReceiptIcon/>
        </Tooltip>,
        link: "/reports/consumptions"
    },   
    {
        title:"Medidores",
        icon:
        <Tooltip title="Medidores">
        <ReceiptIcon/>
        </Tooltip>,
        link: "/reports/measures"
    },      
    {
        title:"Medidores sin consumo",
        icon:
        <Tooltip title="Medidores sin consumo">
        <InvertColorsOffIcon/>
        </Tooltip>,
        link: "/reports/measurer"
    },
    {
        title:"Deudores por Meses",
        icon:
        <Tooltip title="Deudores por Meses">
        <DateRangeIcon/>
        </Tooltip>,
        link: "/reports/measurerCut"
    },
    {
        title:"Detalles de pago",
        icon:
        <Tooltip title="Detalles de pago">
        <ReceiptIcon/>
        </Tooltip>,
        link: "/reports/measurerPay"
    },
    {
        title:"Transferencias",
        icon:
        <Tooltip title="Transferencias">
        <ReceiptIcon/>
        </Tooltip>,
        link: "/reports/transferAssociate"
    },  
];


