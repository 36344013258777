import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import MeasurerCurrent from "./MeasurerCurrent";
import FineAssociateConsumptions from "./FineAssociateConsumptions";
import PayAssociateConsumptions from "./PayAssociateConsumptions";

const usesStyle = makeStyles({
  griContainer : {
    padding: '20px'
  }
});

const Measures = () => {
  const classes = usesStyle();
  return (
    <Grid container spacing={2} justify="center" className={classes.griContainer}>
      <Grid item xs={12} sm={6} md={4}>
        <MeasurerCurrent></MeasurerCurrent>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
       <FineAssociateConsumptions></FineAssociateConsumptions>
      </Grid> 
      <Grid item xs={12} sm={6} md={4}>
       <PayAssociateConsumptions></PayAssociateConsumptions>
      </Grid>
    </Grid>
  );
};

export default Measures;
