import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useStateValue } from "../../contexto/store";
import { saveInstitution } from "../../actions/InstitutionAction";
import { getListInstitution } from "../../actions/InstitutionAction";
import { deleteInstitution } from "../../actions/InstitutionAction";
import { updateInstitution } from "../../actions/InstitutionAction";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const ListInstitution = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();

  const theme = useTheme()

  const columns = [
    { title: "institutionId", field: "institutionId", hidden: true },
    { title: "direction", field: "direction", hidden: true },
    { title: "map", field: "map", hidden: true },
    { title: "Nombre", field: "name" },
    { title: "Acerca", field: "about" },
    { title: "Que hacemos", field: "whatDo" },
    { title: "Telefono", field: "telephone" },
    { title: "Visión ", field: "vision" },
    { title: "Misión ", field: "mission" },
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    const listIntitutions = async () => {
      const response = await getListInstitution();
      setData(response.data);
    };
    listIntitutions();
  }, []);

  const handleRowAdd = (institution, resolve) => {
    const objectIns = {
      Name: institution.name,
      About: institution.about,
      WhatDo: institution.whatDo,
      Telephone: parseInt(institution.telephone || null),
      Direction: "",
      Map: "",
      Vision: institution.vision,
      Mission: institution.mission,
    };

    saveInstitution(objectIns).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo exitosamente la institucion";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const listIntitutions = async () => {
        const response = await getListInstitution();
        setData(response.data);
      };
      listIntitutions();
      resolve();
    });
  };

  const handleRowDelete = (oldData, resolve) => {
    deleteInstitution(oldData.institutionId).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se elimino exitosamente la institucion";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const dataDelete = [...data];
      const index = oldData.tableData.id;
      dataDelete.splice(index, 1);
      setData([...dataDelete]);
      resolve();
    });
  };

  const handleRowUpdate = (newData, oldData, resolve) => {
    /*if(newData.email === "" || validateEmail(newData.email) === false){
      errorList.push("Please enter a valid email")
    }*/

    const updateIns = {
      InstitutionId: newData.institutionId,
      Name: newData.name,
      About: newData.about,
      WhatDo: newData.whatDo,
      Telephone: parseInt(newData.telephone || null),
      Direction: oldData.direction,
      Map: oldData.map,
      Vision: newData.vision,
      Mission: newData.mission,
    };

    updateInstitution(oldData.institutionId, updateIns).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo cambios exitosamente";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const dataUpdate = [...data];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      setData([...dataUpdate]);
      resolve();
    });
  };

  return (
    <div>
      <MaterialTable
        localization={{
          body: {
            editRow: { deleteText: "Esta seguro de eliminar la institucion?",  saveTooltip: "Guardar", cancelTooltip:"Cancelar"},
            addTooltip:"Agregar",
            grouping: {placeholder:"haber"},
            deleteTooltip:"Eliminar",
            editTooltip:"Editar",
          },
          header:{ actions : "Aciones"}
        }}
        options={{
          exportButton: true,
          actionsColumnIndex: -1,
          searchFieldStyle: {
            backgroundColor: '#fff',
            color: '#000',
            borderRadius:9,
          },
          headerStyle: {
            backgroundColor: "#e8f5e9",
            color: "#000",
          },
          paging: true,
          search: true,
        }}
        title="Edicion de Institucion"
        columns={columns}
        data={data}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              handleRowAdd(newData, resolve);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              handleRowUpdate(newData, oldData, resolve);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve);
            }),
        }}
        
        style={{
          borderRadius:20, 
          boxShadow: '-5px 5px 6px 3px rgba(0, 0, 0, .75)',
          backgroundImage: 'linear-gradient(45deg, #1769aa, #c8e6c9, #1769aa',
        }}
      />
    </div>
  );
};

export default ListInstitution;
