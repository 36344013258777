import HttpCliente from "../servicios/HttpCliente";

export const saveContribution = (contribution) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/contributions", contribution)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};


export const saveContributionAdd = (contribution) => {
  return new Promise((resolve) => {
    HttpCliente.post("/contributions/add", contribution)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getListContribution = () => {
  return new Promise((resolve, reject) => {
    HttpCliente.getOnly("/contributions").then((response) => {
      resolve(response);
    });
  });
};

export const deleteContribution = (id) => {
  return new Promise((resolve, reject) => {
    HttpCliente.delete("/contributions/delete/" + id).then((response) => {
      resolve(response);
    });
  });
};

export const updateContribution = (id, contribution) => {
  return new Promise((resolve, reject) => {
    HttpCliente.put("/contributions/" + id, contribution).then(
      (response) => {
        resolve(response);
      }
    );
  });
};

export const getListContributionAdd = (measurerId) => {
  return new Promise((resolve) => {
    HttpCliente.get("/contributions/listAdd", measurerId).then((response) => {
      resolve(response);
    });
  });
};
