import React from 'react';
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import { Tooltip } from '@material-ui/core';

export const MenuAssociate = [    
    {
        title:"Deudas",
        icon:
        <Tooltip title='Deudas'>
        <FormatColorFillIcon/>
        </Tooltip>,
        link: "/RoleAssociate/list",
    },    
    {
        title:"Pagados",
        icon:
        <Tooltip title='Pagados'>
        <FormatColorFillIcon/>
        </Tooltip>,
        link: "/RoleAssociate/listPays",
    },    
];


