import React, { useState } from "react";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import {
  Dialog,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import person from "../../person.jpg";
import Styles from "../Tool/Style";
import { getMeasurerAssociateAction } from "../../actions/ConsumptionAction";
import ListTableConsumptionPay from "./ListTableConsumptionPay";
import Button from "@material-ui/core/Button";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

const MeasurerPay = () => {
  const [open, setOpen] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [code, setCode] = useState("");
  const classes = Styles();
  const [objetAssociate, setObjetAssociate] = useState({
    measurerId: "",
    code: "",
    lastName: "",
    name: "",
    description: "",
    price: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const generateList = () => {
    const list = async () => {
      const response = await getMeasurerAssociateAction(code);
      if (response.data != "") {
        setObjetAssociate({
          measurerId: response.data.measurerId,
          code: response.data.code,
          lastName: response.data.associate.lastName,
          name: response.data.associate.name,
          description: response.data.classification.description,
          price: response.data.classification.price,
        });

        setOpenCard(true);
      }
    };
    list();
    setOpen(false);
  };

  const handleChangeField = (event) => {
    setCode(event.target.value);
  };

  return (
    <>
      <Button
        id="btnItemDateR"
        variant="outlined"
        size="medium"
        color="primary"
        onClick={openDialog}
        style={{ marginTop: 6, marginLeft: 2 }}
      >
        Buscar socio
      </Button>
      {openCard && (
        <Card className={classes.rootCar}>
          <CardHeader
            avatar={
              <Avatar
                aria-label="recipe"
                className={classes.avatarC}
                style={{ backgroundColor: "#000" }}
              >
                {objetAssociate.code.substring(0, 5)}
              </Avatar>
            }
            titleTypographyProps={{ variant: "h6" }}
            subheaderTypographyProps={{ variant: "h6" }}
            title={"Codigo Medidor : " + objetAssociate.code}
            subheader={objetAssociate.lastName + " " + objetAssociate.name}
          />

          <Grid container spacing={1}>
            <Grid
              container
              item
              xs={12}
              md={3}
              direction="column"
              alignItems="center"
            >
              <img
                src={person}
                alt={objetAssociate.name}
                className={classes.poster}
                aling="center"
              />
              <Typography className={classes.LePri}>
                {objetAssociate.description}
              </Typography>
              <Typography>Precio Cubo : {objetAssociate.price}</Typography>
            </Grid>

            <Grid item xs={12} md={9}>
              {objetAssociate.measurerId !== "" && (
                <ListTableConsumptionPay
                  key={objetAssociate.measurerId}
                  valueM={objetAssociate.measurerId}
                  name={objetAssociate.lastName + " " + objetAssociate.name}
                  code={objetAssociate.code}
                />
              )}
            </Grid>
          </Grid>
        </Card>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4" align="center">
            Pago del Medidor
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Ingrese codigo de medidor"
            id="outlined-start-adornment"
            value={code}
            style={{width:'100%'}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Codigo: </InputAdornment>
              ),
            }}
            variant="outlined"
            onChange={handleChangeField}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={generateList} color="primary" variant="contained">
            Generar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MeasurerPay;
