import React, { useState } from "react";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { Grid } from "@material-ui/core";
import person from "../../person.jpg";
import ListTableConsumption from "./ListTableConsumption";
import Styles from "../Tool/Style";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { useStateValue } from "../../contexto/store";
import { saveConsumptionAction } from "../../actions/ConsumptionAction";
import { 
  getListContribution,
  saveContributionAdd,
} from "../../actions/ContributionAction";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const MeasurerCard = (value) => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const classes = Styles();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dateSelect, setDateSelect] = useState(new Date());
  const [personName, setPersonName] = useState([]);
  const [names, setNames] = useState([]);

  const handleClickOpen = () => {
    const list = async () => {
      const response = await getListContribution();
      setNames(response.data);
    };
    list();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const registerNewData = () => {
    // e.preventDefault();
    const objectConsumption = {
      dateConsumption: dateSelect,
      measurerId: value.measurerId,
      contributionId: personName.toString(),
    };

    saveContributionAdd(objectConsumption).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo exitosamente el aporte";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      setOpen(false);
      setIsLoading(!isLoading);
    });
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const valued = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        valued.push(options[i].value);
      }
    }

    setPersonName(valued);
  };

  const mesActual = new Date();
  // mesActual.setDate(mesActual.getDate() - 5);
  // const mesAnterior = new Date();
  // mesAnterior.setDate(mesAnterior.getDate() - 30);

  return (
    <Card className={classes.rootCar}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatarC}  style={{backgroundColor: "#000" }}>
            {value.code.substring(0, 3)}
          </Avatar>
        }
        titleTypographyProps={{variant:'h6' }}
        subheaderTypographyProps={{variant:'h6' }}
        subheader={ value.associate.lastName + " " + value.associate.name }
        title={"Medidor : " + value.code}

      />

      <Grid container spacing={1}>
        <Grid item xs={12} md={3} align={"center"}>
          <img
            src={person}
            alt={value.associate.name}
            className={classes.poster}
          />
          <Typography className={classes.LePri} >{value.classification.description}</Typography>
          <Typography>Precio Cubo : {value.classification.price}</Typography>
          
        </Grid>
        <Grid item xs={12} md={9}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClickOpen}
            style={{ textTransform: "none" }}
            size="small"
          >
            Agregar Aporte
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" align="center">AGREGAR APORTES</DialogTitle>
            <DialogContent>
            <DialogContentText>
              Agregar aportes adicionales al medidor : <b>{value.code} </b> <br/>
              SOCIO : <b>{value.associate.name} {value.associate.lastName} </b>
            </DialogContentText>

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  value={dateSelect}
                  onChange={setDateSelect}
                  margin="normal"
                  id="fecha-publicacion-id"
                  label="Seleccione mes de consumo"
                  format="MM/yyyy"
                  fullWidth
                  // maxDate={mesActual}
                  // minDate={mesAnterior}
                  helperText="Seleccione el mes anterior"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  cancelLabel="Cancelar"
                  okLabel="Seleccionar"
                  views={["month", "year"]}
                />
              </MuiPickersUtilsProvider>

              <InputLabel shrink htmlFor="select-multiple-native">
                Seleccione aportes
              </InputLabel>
              <Select
                multiple
                native
                value={personName}
                onChange={handleChangeMultiple}
                inputProps={{
                  id: "select-multiple-native",
                }}
              >
                {names.map((name) => (
                  <option key={name.contributionId} value={name.contributionId}>
                    {name.name}
                  </option>
                ))}
              </Select>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cerrar
              </Button>
              <Button onClick={registerNewData} color="primary">
                Guardar
              </Button>
            </DialogActions>
          </Dialog>

          <ListTableConsumption key={isLoading} valueM={value.measurerId} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default MeasurerCard;
