import React, { useState, useEffect } from "react";
import MeasurerCard from "./MeasurerCard";
import { Container } from "@material-ui/core";
import { useStateValue } from "../../contexto/store";
import {
  getListConsumption,
  getListConsumptionSearch,
} from "../../actions/ConsumptionAction";

import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const ListCardMeasurer = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [institutionId, setInstitutionId] = useState(
    sesionUsuario.usuario.institutionId
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const [textSearch, setTextSearch] = useState("");

  const [pagedResponse, setPagedResponse] = useState({
    listRecords: [],
    totalRecords: 0,
    numberPageds: 0,
  });

  useEffect(() => {
    const list = async () => {
      setInstitutionId(sesionUsuario.usuario.institutionId);
      const objectPagedRequest = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        institutionId: institutionId,
      };
      const response = await getListConsumption(objectPagedRequest);
      setPagedResponse(response.data);
    };
    list();
  }, []);

  const handleChange = (event, value) => {
    setPageNumber(value);

    if (null != textSearch && "" != textSearch) {
      const list = async () => {
        const objectPagedRequest = {
          pageNumber: value,
          pageSize: pageSize,
          institutionId: institutionId,
          text: textSearch,
        };
        const response = await getListConsumptionSearch(objectPagedRequest);
        setPagedResponse(response.data);
      };
      list();
    } else {
      const list = async () => {
        setInstitutionId(sesionUsuario.usuario.institutionId);
        const objectPagedRequest = {
          pageNumber: value,
          pageSize: pageSize,
          institutionId: institutionId,
        };

        const response = await getListConsumption(objectPagedRequest);
        setPagedResponse(response.data);
      };
      list();
    }
  };

  const handleChangeText = (event) => {
    setTextSearch(event.target.value);
  };

  const handleChangeButtonDefault = () => {
    setTextSearch("");
    setPageNumber(1);
    setPageSize(3);
    const list = async () => {
      setInstitutionId(sesionUsuario.usuario.institutionId);
      const objectPagedRequest = {
        pageNumber: 1,
        pageSize: 3,
        institutionId: institutionId,
      };

      const response = await getListConsumption(objectPagedRequest);
      setPagedResponse(response.data);
    };
    list();
  };

  const handleChangeButton = () => {
    if (null != textSearch && "" != textSearch) {
      setPageNumber(1);
      setPageSize(3);
      const list = async () => {
        const objectPagedRequest = {
          pageNumber: 1,
          pageSize: 3,
          institutionId: institutionId,
          text: textSearch,
        };
        const response = await getListConsumptionSearch(objectPagedRequest);
        setPagedResponse(response.data);
      };
      list();
    } 
  };

  const renderMeasurer = () => {
    if (pagedResponse.listRecords) {
      return pagedResponse.listRecords.map((value, index) => (
        <MeasurerCard key={index} {...value} />
      ));
    }
  };

  return (
    <div>
      <TextField
        id="standard-search"
        label="Buscar "
        type="search"
        value={textSearch}
        onChange={handleChangeText}
      />

      <Button
        variant="outlined"
        size="medium"
        color="primary"
        onClick={handleChangeButton}
        style={{marginTop:6}}
      >
        Buscar
      </Button>
      <Button
        id="btnDefault"
        variant="outlined"
        size="medium"
        color="primary"
        onClick={handleChangeButtonDefault}
        
        style={{marginTop:6, marginLeft:2}}
      >
        Listado
      </Button>
      {renderMeasurer()}
      <Pagination
        count={pagedResponse.numberPageds}
        page={pageNumber}
        onChange={handleChange}
        variant="outlined"
        shape="rounded"
        size="large"
        
      />
    </div>
  );
};

export default ListCardMeasurer;
