import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import {
  getAssociateAction,
  getTransferred,
  saveTransAssociateAction,
} from "../../actions/TransferredAction";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useStateValue } from "../../contexto/store";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "@material-ui/core/Card";

import CardHeader from "@material-ui/core/CardHeader";
import Styles from "../Tool/Style";

const Index = () => {
  const classes = Styles();
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [textSearch, setTextSearch] = useState("");
  const [check, setCheck] = useState(false);
  const [open, setOpen] = useState(false);

  const [associate, setAssociate] = useState({
    name: "",
    lastName: "",
    phoneNumber: "",
    ci: "",
  });

  const [associateN, setAssociateN] = useState({
    nameN: "",
    lastNameN: "",
    phoneNumberN: "",
    ciN: "",
    codeN: "",
  });

  const [associateT, setAssociateT] = useState({
    nameT: "",
    lastNameT: "",
    phoneNumberT: "",
    ciT: "",
  });

  const addValueTemp = (e) => {
    const { name, value } = e.target;

    setAssociate((anterior) => ({
      ...anterior,
      [name]: value,
    }));
  };

  const addValueTempN = (e) => {
    const { name, value } = e.target;

    setAssociateN((anterior) => ({
      ...anterior,
      [name]: value,
    }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (textSearch != "") {
      const list = async () => {
        const response = await getTransferred(textSearch);
        setAssociate(response.data);
      };
      list();
    } else {
      setAssociate({
        name: "",
        lastName: "",
        phoneNumber: "",
        ci: "",
      });
    }
  };

  const handleText = (e) => {
    let text = e.target.value;
    setTextSearch(text);
    if (text === "") {
      setAssociate({
        name: "",
        lastName: "",
        phoneNumber: "",
        ci: "",
      });
    }
  };

  const saveAssociate = (e) => {
    e.preventDefault();

    const objectTrans = {
      fromCode: textSearch,
      toCode: associateN.codeN,
      name: associateN.nameN,
      lastName: associateN.lastNameN,
      phoneNumber: associateN.phoneNumberN,
      ci: associateN.ciN,
      isAssociated: check,
      institutionId: sesionUsuario.usuario.institutionId,
    };
    saveTransAssociateAction(objectTrans).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo exitosamente la transferencia";
        resetForms();
        setOpen(false);
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });
    });
  };

  const resetForms = () => {
    setAssociate({
      name: "",
      lastName: "",
      phoneNumber: "",
      ci: "",
    });

    setTextSearch("");

    setAssociateN({
      nameN: "",
      lastNameN: "",
      phoneNumberN: "",
      ciN: "",
    });

    setCheck(false);
  };

  const handleChangeCheck = (event) => {
    setCheck(event.target.checked);
    setAssociateN({
      nameN: "",
      lastNameN: "",
      phoneNumberN: "",
      ciN: "",
    });
  };

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
    const list = async () => {
      const response = await getAssociateAction(associateN.codeN);
      setAssociateT({
        nameT: response.data.name,
        lastNameT: response.data.lastName,
        phoneNumberT: response.data.phoneNumber,
        ciT: response.data.ci,
      });
    };
    list();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container component="main" maxWidth="xl" justify="center">
      <Card className={classes.rootCar} style={{ padding: 40 }}>
        <CardHeader title={"Transferir Medidor"} />

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              name="search"
              type="search"
              label="Codigo medidor"
              value={textSearch}
              onChange={handleText}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSearch}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name="name"
                variant="outlined"
                fullWidth
                label="Nombre"
                disabled={true}
                value={associate.name}
                onChange={addValueTemp}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="lastName"
                variant="outlined"
                fullWidth
                label="Apellidos"
                disabled={true}
                value={associate.lastName}
                onChange={addValueTemp}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="phoneNumber"
                variant="outlined"
                fullWidth
                label="Telefono"
                disabled={true}
                value={associate.phoneNumber}
                onChange={addValueTemp}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="ci"
                variant="outlined"
                fullWidth
                label="CI"
                disabled={true}
                value={associate.ci}
                onChange={addValueTemp}
              />
            </Grid>
          </Grid>

          <Typography component="h6" variant="h6">
            Llenar los datos correspondientes a quien se quiere realizar la
            transferencia si es socio nuevo. Caso de ser socio, seleccionar "Es
            Socio?" y poner el codigo de medidor.
          </Typography>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={check}
                    onChange={handleChangeCheck}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Es Socio?"
              />
            </Grid>
          </Grid>
          <Grid container justify="center" spacing={2}>
            {check == true && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="codeN"
                    variant="outlined"
                    fullWidth
                    label="CI"
                    value={associateN.codeN}
                    onChange={addValueTempN}
                  />
                </Grid>

                <Grid container justify="center" spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleClickOpen}
                    >
                      Buscar socio
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}

            {check == false && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="nameN"
                    variant="outlined"
                    fullWidth
                    label="Nombre"
                    value={associateN.nameN}
                    onChange={addValueTempN}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="lastNameN"
                    variant="outlined"
                    fullWidth
                    label="Apellidos"
                    value={associateN.lastNameN}
                    onChange={addValueTempN}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    name="phoneNumberN"
                    variant="outlined"
                    fullWidth
                    label="Telefono"
                    value={associateN.phoneNumberN}
                    onChange={addValueTempN}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    name="ciN"
                    variant="outlined"
                    fullWidth
                    label="CI"
                    value={associateN.ciN}
                    onChange={addValueTempN}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={saveAssociate}
                  >
                    Transferir
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </form>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Transferencia</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="nameT"
                  variant="outlined"
                  fullWidth
                  label="Nombre"
                  disabled={true}
                  value={associateT.nameT}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="lastNameT"
                  variant="outlined"
                  fullWidth
                  label="Apellidos"
                  disabled={true}
                  value={associateT.lastNameT}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  name="phoneNumberT"
                  variant="outlined"
                  fullWidth
                  label="Telefono"
                  disabled={true}
                  value={associateT.phoneNumberT}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="cTi"
                  variant="outlined"
                  fullWidth
                  label="CI"
                  disabled={true}
                  value={associateT.ciT}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancelar
            </Button>
            <Button onClick={saveAssociate} color="primary" variant="contained">
              Transferir
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Container>
  );
};

export default Index;
