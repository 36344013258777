import HttpCliente from "../servicios/HttpCliente";

export const saveClassificationAction = (classification) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/classifications", classification)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getListClassification = () => {
  return new Promise((resolve, reject) => {
    HttpCliente.getOnly("/classifications").then((response) => {
      resolve(response);
    });
  });
};

export const deleteClassification= (id) => {
  return new Promise((resolve, reject) => {
    HttpCliente.delete('/classifications/delete/' + id).then((response) => {
      resolve(response);
    });
  });
};

export const updateClassification = (id, classification) => {
  return new Promise((resolve, reject) => {
    HttpCliente.put('/classifications/' + id, classification).then((response) => {
      resolve(response);
    });
  });
};
