import React from 'react';
import ApartmentIcon from "@material-ui/icons/Apartment";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import CancelIcon from "@material-ui/icons/Cancel";
import { Tooltip } from '@material-ui/core';

export const MenuConfig = [
  
    {
        title:"Institucion",
        icon:
        <Tooltip title="Intitucion">
        <ApartmentIcon/>
        </Tooltip>,
        link: "/institution/list"
    },

    {
        title:"Clasificacion",
        icon:
        <Tooltip title="Clasificacion">
        <DashboardIcon/>
        </Tooltip>,
        link: "/classification/list"
    },
    {
        title:"Crear Aportes",
        icon:
        <Tooltip title="Crear Aportes">
        <AddShoppingCartIcon/>
        </Tooltip>,
        link: "/contribution/list"
    },
    {
        title:"Crear Multa",
        icon:
        <Tooltip title="Crear Multa">
        <CancelIcon/>
        </Tooltip>,
        link: "/fine/list"
    },
];