import HttpCliente from "../servicios/HttpCliente";

export const saveInstitution = (institution) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/institutions", institution)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getListInstitution = () => {
  return new Promise((resolve, reject) => {
    HttpCliente.getOnly("/institutions").then((response) => {
      resolve(response);
    });
  });
};

export const deleteInstitution = (id) => {
  return new Promise((resolve, reject) => {
    HttpCliente.delete('/institutions/delete/' + id).then((response) => {
      resolve(response);
    });
  });
};

export const updateInstitution = (id, institution) => {
  return new Promise((resolve, reject) => {
    HttpCliente.put('/institutions/' + id, institution).then((response) => {
      resolve(response);
    });
  });
};
