import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { getMensualExcel, monthConsumptions } from "../../actions/ReportAction";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const MonthConsumptions = () => {
  const [fromDateToday, setFromDateToday] = useState(new Date());
  const [associateExcel, setAssociateExcel] = useState({
    listAssociate: [],
  });
  const getInfoData = () => {
    const listPdf = async () => {
      const objectRequest = {
        fromDate: fromDateToday,
      };
      const res = await monthConsumptions(objectRequest);
      const file = new Blob([res.data], {
        type: "application/pdf",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };

    listPdf();
  };

  const getInfoDataExcel = () => {
    const list = async () => {
      const objectRequest = {
        fromDate: fromDateToday,
      };
      const responsea = await getMensualExcel(objectRequest);
      setAssociateExcel(responsea.data);

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(responsea.data.listAssociate);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        "Reporte mensual " +
          fromDateToday.toLocaleDateString("es-es", {
            year: "numeric",
            month: "short",
          }) +
          fileExtension
      );
    };

    list();
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" align="center">
          Consumo mensual
        </Typography>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            value={fromDateToday}
            onChange={setFromDateToday}
            views={["month", "year"]}
            margin="normal"
            id="fecha-publicacion-id"
            label="Introducir mes"
            format="MM/yyyy"
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            cancelLabel="Cancelar"
            okLabel="Seleccionar"
          />
        </MuiPickersUtilsProvider>
      </CardContent>

      <CardActions>
        <Button
          onClick={getInfoData}
          color="primary"
          variant="contained"
          fullWidth
        >
          Generar pdf
        </Button>

        <Button
          onClick={getInfoDataExcel}
          color="primary"
          variant="contained"
          fullWidth
        >
          Generar excel
        </Button>
      </CardActions>
    </Card>
  );
};

export default MonthConsumptions;
