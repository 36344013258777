import React, { useState, useEffect, Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";

import TableRow from "@material-ui/core/TableRow";
import { getTotalConsumptionAction } from "../../actions/ConsumptionAction";

import Row from "./Row";

const PayTable = ({ value }) => {
  const [total, setTotal] = useState({
    totalPay: 0,
  });

  useEffect(() => {
    const list = async () => {
      const data = [];

      for (let i = 0; i < value.length; i++) {
        data.push(value[i].consumptionId);
      }

      const response = await getTotalConsumptionAction(data.toString());
      setTotal(response.data);
    };
    list();
  }, []);

  return (
    <Fragment>
      <TableContainer>
        <Table size="small" aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Item</TableCell>
              <TableCell align="right">Mes</TableCell>
              <TableCell align="right">Monto</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map((row, index) => (
              <Row value={row} key={index}></Row>
            ))}
            <TableRow>
              <TableCell align="right"></TableCell>
              <TableCell align="right">Suma Total</TableCell>
              <TableCell align="right">{total.totalPay}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default PayTable;
