import { makeStyles } from "@material-ui/core/styles";
import imm from "../../fondb.jpg";

const Style = makeStyles({
    
    root:{
    width:"100%"
    },
  
    paperUno : {
        marginTop : 1,
        // display: "flex",
        width : "99.9%",
        flexDirection: "row",
        alignItems : "center",
    },


    Not : {
      
        width : "99.9%",
        flexDirection: "row",
        // alignItems : "center",
    },

    paper : {
        marginTop : 8,
        display: "flex",
        width : "100%",
        flexDirection: "column",
        alignItems : "center",
    },
    
    form: {
        width : "100%",
        marginTop:20,
        paddingLeft:2,
        paddingRight:2,
    },
    submit : {
        marginTop : 15,
        fontSize: 30,
    },
    // avatar: {
    //     margin : 5,
    //     backgroundColor : 'secondary',
    //     width : 150,
    //     height: 150,
    // },
    icon : {
        fontSize : 100
    },
    marTo: {
        fontSize: 30,
      },
    LogoIcon: {
        
        marginBottom:-250,
        fontSize:'28rem',
        width:"100%",
        paddingLeft:10,
        paddingRight:10,
        paddingTop:18,
        paddingBottom:18,
        color:"secondary",
        // display: "flex",
        backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    
    
    logoU: {
        width: "100%",
        // marginBottom:50,
      },
    logoPe: {
        width: 500,
        height: 130,
        marginBottom:60,
    },
    mentex: {
        paddingLeft:15,
        color:"#000"
    },
    menico: {
        marginLeft:0,
        fontSize:40,
        
        
    },

    menuActive:{
        "&.active": {
          background:'black',
        },
    },
    marginall: {
        paddingLeft:8,
        paddingTop:0,
        paddingBottom:0,
        color:"#000",

    },
    // rootCar: {
    //     // margin: 8,
    //     marginBottom:20,
    //     // backgroundColor: "#c8e6c9",
    //     backgroundImage: 'linear-gradient(45deg, #3fb079, #c8e6c9, #0f6d2d)',
    //     borderRadius:30,
    //     boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)",
    
    //   },

    avatarC: {
         
         height:56, 
         width:57,
         fontSize:33 
    },
    poster: {
        width: 100,
        
        height: 75,
    },
    LePri:{
        fontSize:20,
        fontWeight: 500,
    },

    rootCar: {
        maxWidth: "100%",
            marginBottom: 18,
            borderRadius: 30,
            backgroundSize: '200%',
            boxShadow: '0px 5px 8px 0px rgba(0, 0, 0, .55)',
            transition: '0.5s',
            backgroundImage: 'linear-gradient(45deg, #3174A7, #fff, #3174A7)',
            '&:hover': {
              backgroundPosition: 'center',
              boxShadow: '0px 10px 16px 0px rgba(0, 0, 0, .55)',
            }
        },

        fondoLogin: {
            backgroundImage: `linear-gradient(rgba(255, 255,255, 0.8), rgba(0, 168, 255, 0.2)), url(${imm})`,
            backgroundPosition: "center",
            textAlign: 'center',
            // backgroundRepeat: "no-repeat",
            // backgroundPosition: "center center",
            backgroundSize: "cover",
            paddingBottom:220,
            // color: "#fff",
            paddingLeft:5,
            paddingRight:5,
          },

});

export default Style;