import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import style from "../Tool/Style";
import { saveNoticeAction } from "../../actions/NoticeAction";
import { useStateValue } from "../../contexto/store";

// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';


const NewNotice = () => {
  //traer variable global de los mensajes y usuario aunque no lo use por el momento
  const [{ sesionUsuario }, dispatch] = useStateValue();

  //costante creacion de variables de objeto socio para estado
  const [notice, setNotice] = useState({
    title: "",
    description: "",
    dateStart: null,
    dateEnd: "",
    institutionId: "EEB5FA29-D7C9-404C-0147-08D8185B4E74",
  });

  const saveNotice = (e) => {
    e.preventDefault();

    //constante para del objeto socio para guardar en la BD seteados los valores del formulario
    const objectNotice = {
      title: notice.title,
      description: notice.description,
      dateStart: parseInt(notice.dateStart || null),
      dateEnd: notice.dateEnd,
      institutionId: "EEB5FA29-D7C9-404C-0147-08D8185B4E74",
    };

    //llamar al Action socio
    saveNoticeAction(objectNotice).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo exitosamente la nueca NOticia";
        resetForm();
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });
    });

    const resetForm = () => {
      setNotice({
        title: "",
        description: "",
        dateStart: "",
        dateEnd: "",
        institutionId: "EEB5FA29-D7C9-404C-0147-08D8185B4E74",
      });
    };
  };

  //aqui se setea valores de los inputs del formulario par poder escribir si o si debe estar
  const addValueTemp = (e) => {
    const { name, value } = e.target;

    setNotice((anterior) => ({
      ...anterior,
      [name]: value,
    }));
  };
 
  const [selectedDate, setSelectedDate] = useState(new Date('2014-08-18T21:11:54'));

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  return (
    <Container component="main" justify="center"  style={style.iz}>
      <div style={style.paper}>
        <Typography component="h1" variant="h5">
          Registro de Nueva Noticia
        </Typography>
        
        <form style={style.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="title"
                variant="outlined"
                fullWidth
                label="Titulo"
                value={notice.title}
                onChange={addValueTemp}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                variant="outlined"
                fullWidth
                label="detalle de la noticia"
                value={notice.description}
                onChange={addValueTemp}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <TextField
                name="dateStart"
                variant="outlined"
                fullWidth
                label="Fecha de inicio"
                value={notice.dateStart}
                onChange={addValueTemp}
              /> */}
            </Grid>
            <Grid item xs={12}>
              {/* <TextField
                name="dateEnd"
                variant="outlined"
                fullWidth
                label="Fecha de fin"
                value={notice.dateEnd}
                onChange={addValueTemp}
              /> */}
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} md={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                style={style.submit}
                onClick={saveNotice}
              >
                Guardar Nueva Noticia
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default NewNotice;
