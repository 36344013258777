import React, { useState, useEffect, Fragment } from "react";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  getListContribution,
} from "../../actions/ConsumptionAction";


const Row = ({ value }) => {

  const [data, setData] = useState({
    consumptionId: "",
    contributions: [],
    dateConsumption: "",
    fines: [],
    price: 0,
    totalPay :0,
  });

  useEffect(() => {
    const list = async () => {
      const response = await getListContribution(value.consumptionId);
      setData(response.data);
    };
    list();
  }, []);

  const dateBuild = (date) => {
      const newDate = new Date(date);
      const month = newDate.toLocaleDateString('default', { month: 'long' })
      return month + " " + newDate.getFullYear() ;
  }

  return (
    <Fragment>
      <TableRow key={data.consumptionId}>
        <TableCell align="right">Consumo agua</TableCell>
        <TableCell align="right">{ dateBuild(data.dateConsumption)}</TableCell>
        <TableCell align="right">{data.price}</TableCell>
      </TableRow>

      {data.fines.map((fine) => (
        <TableRow key={fine.fineId}>
          <TableCell align="right">{fine.name}</TableCell>
          <TableCell align="right">{ dateBuild(data.dateConsumption)}</TableCell>
          <TableCell align="right">{fine.price}</TableCell>
        </TableRow>
      ))}

      {data.contributions.map((contribution) => (
        <TableRow key={contribution.contributionId}>
          <TableCell align="right">{contribution.name}</TableCell>
          <TableCell align="right">{ dateBuild(data.dateConsumption)}</TableCell>
          <TableCell align="right">{contribution.price}</TableCell>
        </TableRow>
      ))}

      <TableRow>
        <TableCell align="right"></TableCell>
        <TableCell align="right">Total Mes</TableCell>
      <TableCell align="right">{data.totalPay}</TableCell>
      </TableRow>
    </Fragment>
  );
};

export default Row;
