import HttpCliente from "../servicios/HttpCliente";


export const getListConsumption = (objectRequest) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/associates/measurers", objectRequest).then((response) => {
      resolve(response);
    });
  });
};

export const getListConsumptionSearch = (objectText) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/associates/searchMeasurer", objectText).then((response) => {
      resolve(response);
    });
  });
};

export const saveConsumptionAction = (consumption) => {
  return new Promise((resolve) => {
    HttpCliente.post("/consumptions", consumption)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const savePasiveConsumptionAction = (consumption) => {
  return new Promise((resolve) => {
    HttpCliente.post("/consumptions/pasive", consumption)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const saveConsumptionPayAction = (consumption) => {
  return new Promise((resolve) => {
    HttpCliente.post("/consumptions/pay", consumption)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const saveConsumptionFineAction = (consumption) => {
  return new Promise((resolve) => {
    HttpCliente.post("/consumptions/fine", consumption)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getListConsumptionAction = (measurerId) => {
  return new Promise((resolve) => {
    HttpCliente.get("/consumptions/list", measurerId).then((response) => {
      resolve(response);
    });
  });
};

export const getListConsumptionPayAction = (measurerId) => {
  return new Promise((resolve) => {
    HttpCliente.get("/consumptions/listPay", measurerId).then((response) => {
      resolve(response);
    });
  });
};

export const getListConsumptionPdf = (consumptionId) => {
  return new Promise((resolve) => {
    HttpCliente.getPdf("/consumptions/pdf", consumptionId).then((response) => {
      resolve(response);
    });
  });
};

export const getListConsumptionPayPdf = (consumptionId) => {
  return new Promise((resolve) => {
    HttpCliente.getPdf("/consumptions/payPdf", consumptionId).then((response) => {
      resolve(response);
    });
  });
};

export const getLastConsumptionAction = (measurerId) => {
  return new Promise((resolve) => {
    HttpCliente.get("/consumptions/last", measurerId).then((response) => {
      resolve(response);
    });
  });
};

export const getListContribution = (contributionId) => {
  return new Promise((resolve) => {
    HttpCliente.get("/consumptions/contribution", contributionId).then((response) => {
      resolve(response);
    });
  });
};

export const deleteConsumptionAction = (consumptionId) => {
  return new Promise((resolve, reject) => {
    HttpCliente.delete('/consumptions/delete/' + consumptionId).then((response) => {
      resolve(response);
    });
  });
};

export const deleteContributionAction = (ConsumptionContributionId) => {
  return new Promise((resolve) => {
    HttpCliente.delete('/consumptions/deleteContribution/' + ConsumptionContributionId).then((response) => {
      resolve(response);
    });
  });
};

export const updateConsumptionAction = (consumptionId, consumption) => {
  return new Promise((resolve) => {
    HttpCliente.put('/consumptions/' + consumptionId, consumption).then((response) => {
      resolve(response);
    });
  });
};


export const getTotalConsumptionAction = (listId) => {
  return new Promise((resolve) => {
    HttpCliente.get("/consumptions/contributionTotal", listId).then((response) => {
      resolve(response);
    });
  });
};


export const getMeasurerAssociateAction = (code) => {
  return new Promise((resolve) => {
    HttpCliente.get("/consumptions/code", code).then((response) => {
      resolve(response);
    });
  });
};