import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useStateValue } from "../../contexto/store";
import {
  saveConsumptionFineAction,
  getListConsumptionPayAction,
} from "../../actions/ConsumptionAction";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import PayTable from "./PayTable";
import Typography from "@material-ui/core/Typography";

const ListTableConsumptionPay = ({ valueM, name, code }) => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    const list = async () => {
      const response = await getListConsumptionPayAction(valueM);
      setData(response.data);
    };
    list();
  }, []);

  const columns = [
    { title: "ConsumptionId", field: "consumptionId", hidden: true },
    {
      title: "Mes",
      field: "dateConsumption",
      // width: 500,
      editable: "onAdd",
      headerStyle: {
        minWidth: 100,
        maxWidth: 140,
        padding: 0,
        // margin:0,
      },
      cellStyle: {
        minwidth: 100,
        maxWidth: 140,
        padding: 0,
        // margin:0,
      },
      render: (rowData) => (
        <Typography>{dateBuild(rowData.dateConsumption)}</Typography>
      ),
    },
    { title: "Es Inicio?", field: "isStart", type: "boolean", hidden: true },
    { title: "Inicio", field: "start", hidden: true },
    {
      title: "Actual",
      field: "present",
      headerStyle: {
        minWidth: 40,
        maxWidth: 50,
        padding: 0,
        textAlign: "center",
        // margin:0,
      },
      cellStyle: {
        minwidth: 40,
        maxWidth: 50,
        padding: 0,
        textAlign: "center",
        // margin:0,
      },
    },    
    {
      title: "Anterior",
      field: "previusConsumption",
      editable: "never",
      headerStyle: {
        minWidth: 40,
        maxWidth: 70,
        textAlign: "center",
        padding: 0,
        // margin:0,
      },
      cellStyle: {
        minwidth: 40,
        maxWidth: 70,
        textAlign: "center",
        padding: 0,
        // margin:0,
      },
    },

    {
      title: "Consumo (m³)",
      field: "totalConsumption",
      editable: "never",
      type: "numeric",
      headerStyle: {
        minWidth: 130,
        // maxWidth: 100,
        padding: 0,
        textAlign: "center",

        // margin:0,
      },
      cellStyle: {
        minwidth: 130,
        // maxWidth: 100,
        padding: 0,
        textAlign: "center",

        // margin:0,
      },
    },

    

    {
      title: "F. Pago",
      field: "dateUpdate",
      // width: 500,
      //editable: "onAdd",
      headerStyle: {
        minWidth: 100,
        maxWidth: 140,
        padding: 0,
        // margin:0,
      },
      cellStyle: {
        minwidth: 100,
        maxWidth: 140,
        padding: 0,
        // margin:0,
      },
      render: (rowData) => (
        <Typography>{dateBuildUpdate(rowData.dateUpdate)}</Typography>
      ),
    },

    { title: "Detalle Inicio", field: "detailStart", hidden: true },
  ];

  const dateBuild = (date) => {
    const newDate = new Date(date);
    const month = newDate.toLocaleDateString("default", { month: "long" });
    return month + " " + newDate.getFullYear();
  }; 

  const dateBuildUpdate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleString();
  };

  const handleSelection = (rows) => {
    if (rows.length > 0) {
      setList(rows);

     /* rows.map((row) => {
        const fineObject = {
          ConsumptionId: row.consumptionId,
        };
        saveConsumptionFineAction(fineObject);
      });*/
      setVisible(true);
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <MaterialTable
        localization={{
          body: {
            editRow: {
              deleteText: "Esta seguro de eliminar el consumo?",
              saveTooltip: "Guardar",
              cancelTooltip: "Cancelar",
            },
            emptyDataSourceMessage: " sin deudas pendientes...",
            addTooltip: "Agregar",
            grouping: { placeholder: "haber" },
            deleteTooltip: "Eliminar",
            editTooltip: "Editar",
          },
          toolbar: {
            nRowsSelected: "Meses pagados: {0}",
          },
          header: { actions: "Acciones" },
        }}
        options={{
          exportButton: false,
          actionsColumnIndex: 0,
          selection: true,
          padding: "dense",
          isFreeAction: true,
          actions: false,
          search: false,
          paging: false,
          headerStyle: {
            backgroundColor: "#e8f5e9",
            color: "#000",
          },
        }}
        title="LECTURAS DE MESES PAGADOS"
        columns={columns}
        data={data}
        onSelectionChange={(rows) => handleSelection(rows)}
        style={{
          marginLeft: 2,
          marginRight: 2,
          marginBottom: 5,
          borderRadius: 20,
        }}
      />

      {visible && (
        <Dialog
          open={visible}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Typography component="p" align="center">
              DETALLES DE PAGO DEL MEDIDOR:
              <b> {code} </b>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography component="p" style={{ marginBottom: 10 }}>
              <b> Socio : </b>
              {name}
            </Typography>

            <PayTable value={list}></PayTable>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default ListTableConsumptionPay;
